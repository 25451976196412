const globalValues=require("./global");

//
// Set the reference table color.
//
async function setTableColor(tableColor) {
    await Word.run(async (context) => {
      var doc= context.document;
      var docBody = context.document.body;
      var myTables = context.document.body.tables;
      // sync
      await context.sync();

      var serviceNameContentControl = doc.contentControls.getByTitle("first");
      // load from docx
      context.load(serviceNameContentControl,'text');
      context.load(myTables);
      // sync
      await context.sync();

      if (serviceNameContentControl.items.length != 0) {
        console.log('there is a table? ');
        for (var i=0;i<myTables.items.length;i++){
            myTables.items[i].load('parentContentControlOrNullObject');
            // sync
             await context.sync();
             var myCC=myTables.items[i].parentContentControlOrNullObject
             if (myCC.title === "first"){
                console.log('there is a table? change the color! to',tableColor);
                if (tableColor==0){
                  myTables.items[i].styleBuiltIn = Word.Style.gridTable5Dark_Accent2;
                } else if(tableColor==1){
                  myTables.items[i].styleBuiltIn = Word.Style.gridTable5Dark;
                } else if(tableColor==2){
                  myTables.items[i].styleBuiltIn = Word.Style.gridTable5Dark_Accent1;
                }else if(tableColor==3){
                  myTables.items[i].styleBuiltIn = Word.Style.gridTable5Dark_Accent3;
                }else if(tableColor==4){
                  myTables.items[i].styleBuiltIn = Word.Style.gridTable5Dark_Accent4;
                } else if(tableColor==5){
                  myTables.items[i].styleBuiltIn = Word.Style.gridTable5Dark_Accent5;
                } else if(tableColor==6){
                  myTables.items[i].styleBuiltIn = Word.Style.gridTable5Dark_Accent6;
                } else {
                  myTables.items[i].styleBuiltIn = Word.Style.plainTable1;
                }


               myTables.items[i].styleFirstColumn = false;
               await context.sync();
             }
           }
      }
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}

/*
 *
 */
 async function getTableColor() {
     await Word.run(async (context) => {
       var doc= context.document;
       var docBody = context.document.body;
       var myTables = context.document.body.tables;
       // sync
       await context.sync();

       var serviceNameContentControl = doc.contentControls.getByTitle("first");
       // load from docx
       context.load(serviceNameContentControl,'text');
       context.load(myTables);
       // sync
       await context.sync();
       if (serviceNameContentControl.items.length != 0) {
         for (var i=0;i<myTables.items.length;i++){
             myTables.items[i].load('parentContentControlOrNullObject');
             // sync
              await context.sync();
              var myCC=myTables.items[i].parentContentControlOrNullObject
              if (myCC.title === "first"){
                const colorIndex=globalValues.tableColor.indexOf(myTables.items[i].styleBuiltIn);
                globalValues.setOption("tableColor",colorIndex);
              }
            }
       } else {
         globalValues.setOption("tableColor",0);
       }
     })
     .catch(function (error) {
         console.log("Error: " + error);
         if (error instanceof OfficeExtension.Error) {
             console.log("Debug info: " + JSON.stringify(error.debugInfo));
         }
     });

     // loop through the table colors and check which one was checked.
     let items=  document.getElementsByClassName("checkbox-round");
     items.forEach((item, i) => {
       item.checked=false;
       if(globalValues.getOption("tableColor") === String(i) ){
         item.checked=true;
       }
     });
 }
//
// Export
//
module.exports={
   setTableColor,getTableColor
}
