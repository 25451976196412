// images references in the manifest
import "../../assets/icon-16.png";
import "../../assets/icon-32.png";
import "../../assets/icon-80.png";
//import { base64File } from "./base64File";

var checkRefStyle=require("./referenceStyle");
var asyncUpdateContentControl=require("./updateContentControl");
const globalValues=require("./global");
const renderPDF=require("./renderPDFAll");
const ooxml=require("./ooxml");
const load=require("./load");
var setSelect=require("./select");
const insertRef=require("./insertReference");

const text =require('./textSettings');
//const initRefNumber=require('./options');
const options=require('./options');
const pdfBuilder=require('./pdfBuilderHelper');
const delPDF=require("./delBase64");
const checkPDF=require("./checkBase64");
const refTable=require("./refTable");

var searchValueGlobal=globalValues.searchValueGlobal;

/* global document, Office, Word */
Office.onReady((info) => {
  if (info.host === Office.HostType.Word) {
    // load the language settings.
    //checkRefLang();

    load();
    setSelect();
    if (!Office.context.requirements.isSetSupported('WordApi', '1.3')) {
      UIkit.notification({
        message: text.HomeHTML.WordApiError[globalValues.language],
        status: 'danger',
        pos: 'top-center',
        timeout: 4000
      });
    }
    // check which language is used and set the app language.
    //displayLanguage();
    options.setAppLanguage();
    // Assign event handlers and other initialization logic.
    if (globalValues.getValue(globalValues.Setting.Demomode)) {
      document.getElementById("insert-reference").disabled=false;
      document.getElementById("loggedInAs").textContent=globalValues.getValue(globalValues.Setting.Username);
    } else {
      document.getElementById("btnDropdown").disabled= false; //true
      UIkit.notification({
        message: text.HomeHTML.TrialModeAlt[globalValues.language],
        status: 'warning',
        pos: 'top-center',
        timeout: 3000
      });
    }
    const contextInfo = Office.context.diagnostics;
    console.log("Office application: " + contextInfo.platform);

    document.getElementById("insert-reference").onclick =verifyReference;//asyncInsertReference;
    if (contextInfo.platform != 'OfficeOnline'){
      document.getElementById("pdfBuilder").onclick= pdfBuilder.fileManager;
    } else {
      document.getElementById("pdfBuilder").onclick= pdfBuilder.fileManager;
      //document.getElementById("pdfBuilder").onclick= fileManagerError;
    }
    //document.getElementById("ooxml").onclick= delAllFilesTest;//ooxml.getOOXML_paragraph;
    document.getElementById("btnDropdown").onclick= newTest;
    document.getElementById("save").onclick = saveOptions;
    document.getElementById("cancel").onclick = cancelOptions;
    document.getElementById("updateBtn").onclick = manualUpdate;
    //document.getElementById("onoff").onclick= onoff;
    //document.getElementById("delete").onclick= pdfDelete;
    //document.getElementById("return").onclick= pdfReturn;
    //document.getElementById("returnOffOn").onclick= pdfReturnOffOn;
    document.getElementById("sideload-msg").style.display = "none";
    document.getElementById("app-body").style.display = "flex";

    //document.getElementById("lang1").onclick=onSelect;
    //document.getElementById("lang2").onclick=onSelect;
    document.getElementById("lang1").addEventListener('click', onSelect);
    document.getElementById("lang2").addEventListener('click', onSelect);
    // update document order on docx load
    //asyncUpdate();
    setOptions().then(asyncUpdateContentControl(false));
    //onOffInit();

  }
});

function fileManagerError(){
  UIkit.notification({
    message: text.HomeHTML.WordApiError[globalValues.language],
    status: 'danger',
    pos: 'top-center',
    timeout: 4000
  });
}

function manualUpdate(){
  asyncUpdateContentControl(false);
}
/*
*
*/
function onSelect(e){
  //const langSelect=this.childNodes[2].innerText;
  //const langSelect=e.target.innerText;
  options.updateAppLanguage(e.target.innerText);
  globalValues.setOption("lang",e.target.innerText.toLowerCase());
}
/*
 * Get the language setting and set the global variable.
*/
function displayLanguage() {
    const myDisplayLanguage = Office.context.displayLanguage;
    console.log("Office language: ", myDisplayLanguage);
    switch (myDisplayLanguage) {
        case 'en-US':
            language='en';
            break;
        case 'nl-NL':
            language='nl';
            break;
        default:
            language='nl';
    }
    globalValues.language=language;
}
function delAllFilesTest(){
  delPDF.delAllRefFiles('',true,false);
}

function cancelOptions(){
  const initNmbr=globalValues.getOption("initNmbr")
  if(initNmbr){
    document.getElementById("quantity").value=initNmbr;
    globalValues.initRefNmbr=initNmbr;
  }
  if(globalValues.getOption("prfx")==='true'){
    document.getElementById("prefix").checked=true;
    globalValues.prefix=true;
  } else {
    document.getElementById("prefix").checked=false;
    globalValues.prefix=false;
  }
  if(globalValues.getOption("bracket")==='true'){
    document.getElementById("bracket").checked=true;
    globalValues.bracket=true;
  } else {
    document.getElementById("bracket").checked=false;
    globalValues.bracket=false;
  }

  if(globalValues.getOption("update")==='true'){
    document.getElementById("autoUpdate").checked=true;
    globalValues.autoUpdate=true;
    document.getElementById("syncicon").innerHTML="sync_disabled";
  } else {
    document.getElementById("autoUpdate").checked=false;
    globalValues.autoUpdate=false;
    document.getElementById("syncicon").innerHTML="sync";
  }

  if(globalValues.getOption("break")==='true'){
    document.getElementById("break").checked=true;
    globalValues.spaceBreak=true;
  } else {
    document.getElementById("break").checked=false;
    globalValues.spaceBreak=false;
  }

  // loop through the table colors and check which one was checked.
  let items=  document.getElementsByClassName("checkbox-round");
  items.forEach((item, i) => {
    item.checked=false;
    if(globalValues.getOption("tableColor") === String(i) ){
      item.checked=true;
    }
  });

  if(document.getElementById("refStyle").innerHTML){
    console.log(document.getElementById("refStyle"));
    document.getElementById("refStyle").innerHTML=globalValues.searchValueGLB;
  }
  document.getElementById("myNav").style.width = "0%";
}
/*
 * Set the Menu options on load.
 */
async function setOptions(){
  refTable.getTableColor();
  checkRefStyle().then(options.initRefNumber());
  if(globalValues.getOption("prfx")==='true'){
    document.getElementById("prefix").checked=true;
    globalValues.prefix=true;
  } else {
    document.getElementById("prefix").checked=false;
    globalValues.prefix=false;
  }
  if(globalValues.getOption("bracket")==='true'){
    document.getElementById("bracket").checked=true;
    globalValues.bracket=true;
  } else {
    document.getElementById("bracket").checked=false;
    globalValues.bracket=false;
  }
  if(document.getElementById("autoUpdate").checked){
    globalValues.setOption("update",true);
    globalValues.autoUpdate=true;
  }else {
    globalValues.setOption("update",false);
    globalValues.autoUpdate=false;
  }
  if(document.getElementById("break").checked){
    globalValues.setOption("break",true);
    globalValues.spaceBreak=true;
  }else {
    globalValues.setOption("break",false);
    globalValues.spaceBreak=false;
  }
  checkPDF.checkFile();

}
function saveOptions(){
  if(document.getElementById("quantity").value){
    const initNmbr=document.getElementById("quantity").value;
    globalValues.initRefNmbr=initNmbr;
    globalValues.setOption("initNmbr",initNmbr);
  }

  if(document.getElementById("prefix").checked){
    globalValues.setOption("prfx",true);
    globalValues.prefix=true;
  }else {
    globalValues.setOption("prfx",false);
    globalValues.prefix=false;
  }

  if(document.getElementById("bracket").checked){
    globalValues.setOption("bracket",true);
    globalValues.bracket=true;
  }else {
    globalValues.setOption("bracket",false);
    globalValues.bracket=false;
  }

  if(document.getElementById("autoUpdate").checked){
    globalValues.setOption("update",true);
    globalValues.autoUpdate=true;
    document.getElementById("syncicon").innerHTML="sync_disabled";
    document.getElementById("updateBtn").disabled=true;
  }else {
    globalValues.setOption("update",false);
    globalValues.autoUpdate=false;
    document.getElementById("syncicon").innerHTML="sync";
    document.getElementById("updateBtn").disabled=false;
  }

  if(document.getElementById("break").checked){
    globalValues.setOption("break",true);
    globalValues.spaceBreak=true;
  }else {
    globalValues.setOption("break",false);
    globalValues.spaceBreak =false;
  }
  // loop through the table colors and check which one is checked.
  let items=  document.getElementsByClassName("checkbox-round");
  items.forEach((item, i) => {
    if(item.checked){
      console.log(item.id,i);
      globalValues.setOption("tableColor",i);
      refTable.setTableColor(i);
    }
  });

  if(document.getElementById("refStyle").innerHTML){
    globalValues.searchValueGLB=document.getElementById("refStyle").innerHTML;
    switch (globalValues.language) {
        case 'en':
            document.getElementById("insert-reference").innerHTML=`Insert new ${globalValues.searchValueGLB}`;
            break;
        case 'nl':
            document.getElementById("insert-reference").innerHTML=`Nieuwe ${globalValues.searchValueGLB} invoegen`;
            break;
    }
  }
  asyncUpdateContentControl(false);
  document.getElementById("myNav").style.width = "0%";
}
//
// set timer for periodically checking if update is required.
//

  setInterval(checkForUpdate, 2000);

//
// Function to perform an update of the docx.
//
async function checkForUpdate(){
  if (!globalValues.autoUpdate) return;
  await Word.run(async function (context) {
  var doc= context.document;
  // Load from docx.
  var resultRef = doc.contentControls.getByTitle(globalValues.ccRefTitle);
  const resultPDF = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
  context.load(resultPDF,'tag');
  context.load(resultRef,'tag');
  // sync.
  await context.sync();
  // if number of references inserted is not equal to number of references
  // periodically load from docx, then reference is deleted and  update is needed.
  if (globalValues.refNumberDocx.length!=resultRef.items.length){
    console.log('reference deleted')
    console.log(globalValues.refNumberDocx.length,resultRef.items.length);
    // Check if files must be added.
    if (globalValues.autoFileAdd){
      // update the references, delete the files and update the file numbers.
      asyncUpdateContentControl(false).then(  renderPDF.delFiles).then(renderPDF.updateFileNumbers);
    } else {
      //asyncUpdateContentControl().then(  delPDF.delAllRefFiles('',false,false));
      asyncUpdateContentControl(true);
    }
  }
  // sync.
  await context.sync();
})
.catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
});
}
//
//
//
function insertRefFile(){

  if (globalValues.autoFileAdd){
      renderPDF.getFile();
    } else {
      insertRef();
    }
}
//
//
//
function checkRefLang(){
  Word.run(function (context) {

    var doc = context.document.body;
    // get text from docx.
    var resultRef = doc.contentControls.getByTitle(globalValues.ccRefTitle);
    context.load(resultRef,'text');

    // Synchronize the document state by executing the queued commands,
    // and return a promise to indicate task completion.
    return context.sync().then(function () {

        if (resultRef.items.length != 0) {

                    console.log('Content control found.');
                    const refLng= ['nl','en'];
                    for (var j=0;j<refLng.length;j++){
                      var refItems= Object.values(globalValues.refStyleTest[refLng[j]]);
                      for (var i=0;i<refItems.length;i++){
                        const foundRef= resultRef.items[0].text;
                        if (foundRef.match(refItems[i])){
                          console.log(refItems[i],refLng[j]);
                        }
                      }
                    }



        }
        load();
        setSelect();
    });
})
.catch(function (error) {
    console.log('Error: ' + JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
    }
});

}
//
//
//
function bookmarks(){
  Word.run(function (context)
  {
      let range = context.document.getSelection();
      return context.sync().then(function ()
      {
          //let insertedTextRange = range.insertText(`Test Bookmark`, Word.InsertLocation.replace);

          let uniqueStr = new Date().getTime();
          let bookmarkName = `Test_BookmarkCode_${uniqueStr}`;
          range.insertBookmark(bookmarkName);
      });
  });
}
//
// insesrt cross references
//
function insertCrossReference() {
    Word.run(function (context) {
      var searchValue="zie productie ";
      var doc= context.document;
      var docBody = context.document.body;
      var crossRefs=document.getElementById('myForm');
      var optionFromForm=crossRefs.value;

      var range= doc.getSelection();

      // sync with docx
      return context.sync().then(function () {
        let firstWord = optionFromForm.split(" ")[0];
        range.insertText(` ${searchValue+firstWord} `,'replace');
      })
      .then(context.sync);
      // Set dropdown list to  first input after selection is inserted in text.
      //setSelectedIndex(crossRefs,'Choose and insert cross reference');

    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}
//
// Insert references and add to table
//
function insertReference() {
    Word.run(function (context) {
      var doc= context.document;
      var docBody = context.document.body;
      var txt= document.getElementById("description").value;
      var range= doc.getSelection();
      var serviceNameContentControl = doc.contentControls.getByTitle("first");
      var myTables = context.document.body.tables;
      var searchValue= 'hello';
      var searchResults=docBody.search(searchValue,{ignorePunct: true,matchWholeWord:true});
      // load from docx
      context.load(searchResults,'text');
      context.load(serviceNameContentControl,'text');
      context.load(myTables);

      // sync
      return context.sync().then(function () {
        // check if there is a table in the document if not create one
        // else append to this table
        if (serviceNameContentControl.items.length === 0) {
          // insert reference docx
          range.insertText(`${searchValue} 0 `,'replace');
          // built table
          let data = [
            ["productie", "omschrijving"],
            [searchResults.items.length, txt]
          ];
          // table style and content control
          var table = docBody.insertTable(2, 2, "end", data);
          table.styleBuiltIn = Word.Style.gridTable5Dark_Accent2;
          table.styleFirstColumn = false;
          var tableContentControl= table.insertContentControl();
          tableContentControl.title="first";
          }
        else {
          for (var i=0;i<myTables.items.length;i++){
              myTables.items[i].load('parentContentControlOrNullObject');
              return context.sync().then(function() {
                var myCC=myTables.items[i].parentContentControlOrNullObject
                if (myCC.isNullObject){
                docBody.insertText(`There are tables in document ${myTables.items[i].parentContentControlOrNullObject}`,'start');

             } else {
               docBody.insertText(`Title of table is ${myTables.items[i].parentContentControlOrNullObject.title}`,'start');
               myTables.items[i].addRows('end',1);
             }
           })
            }
        }

      })
      .then(context.sync);

      //return context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}
/*
 * Check before reference is inserted if a description is provided in the text
 * field. If not give warning.
*/

function verifyReference(){
  if (document.getElementById("description").value===""){
    UIkit.notification({
      message: text.AppMsg.EmptyField[globalValues.language],
      status: 'warning',
      pos: 'top-center',
      timeout: 2000
    });
  } else {
    asyncInsertReference();
  }
}

//
// Insert references and add to table
//
async function asyncInsertReference() {
  // Limit the number references when in demoMode.
  /*if (!globalValues.getValue(globalValues.Setting.Demomode)){
    if (globalValues.maxNumberOfRefs>1) document.getElementById("insert-reference").disabled=true;
    globalValues.maxNumberOfRefs++;
    console.log('number of clicks',globalValues.maxNumberOfRefs)
  }*/
    await Word.run(async function (context) {
      var doc= context.document;
      var docBody = context.document.body;
      var myTables = context.document.body.tables;
      var paragraphs = context.document.body.paragraphs;
      // sync
      await context.sync();

      // Get the reference style
      var searchValue= globalValues.searchValueGLB;
      var searchResultsBold=1;
      // get app content
      var txt= document.getElementById("description").value;
      // get docx content
      var rangeInt= doc.getSelection();
      var serviceNameContentControl = doc.contentControls.getByTitle("first");
      var test = doc.contentControls.getByTitle("ref");

      var searchResults=docBody.search(searchValue,{ignorePunct: true,matchWholeWord:true});
      // load from docx
      context.load(searchResults,['text','font']);
      context.load(serviceNameContentControl,'text');
      context.load(test,'text');
      context.load(myTables);
      context.load(paragraphs, 'listItemOrNullObject' );
      // sync
      await context.sync();
      // First at a space to detach reference CC box fromt text
      rangeInt.insertText(` `,'End');

      // Get the new range
      var range= doc.getSelection();
      // sync.
      await context.sync();
      // DEbug info:
      if (test.items.length===0){
        console.log('no reference items');
      } else {
        console.log(test);
      }
      // Count number of boldface searchResults
        for (var i=0;i<searchResults.items.length;i++){
        if (searchResults.items[i].font.bold){
            searchResultsBold=searchResultsBold+1;
        }
      }
        // check if there is a table in the document if not create one
        // else append to this table
        if (serviceNameContentControl.items.length === 0) {
          // insert reference docx
            if (globalValues.spaceBreak){
              range.insertText(`${searchValue}\u00A01`,'replace');
            } else {
              range.insertText(`${searchValue} 1`,'replace');
            }
            range.font.bold=true;
            //range.insertHtml(`<b>${searchValue} 1</b>`, 'Replace');
          //
          // content control is added on 29012022 must be further build
          //
          var searchValueContentControl = range.insertContentControl();
          //searchValueContentControl.insertText(`${searchValue} 1`,'replace');
          searchValueContentControl.title = "ref";
          searchValueContentControl.tag = "1";
          searchValueContentControl.cannotEdit=false; //true;
          searchValueContentControl.appearance = "BoundingBox";//Hidden";
          searchValueContentControl.placeholderText=text.AppMsg.PlaceHolderText[globalValues.language];
          //searchValueContentControl.color = "blue";
          rangeInt.select("End");
          await context.sync();

          //notEdit();
          //
          //
          //
          //range.insertParagraph('','after').detachFromList();
          //var pageBreak= docBody.insertBreak('page','end');
          // Check if last paragraph is in list then detach new entry form list
          var paragraph = paragraphs.items[paragraphs.items.length-1];
          if (paragraph.listItemOrNullObject.isNullObject){
              //paragraph.insertBreak('next', 'after'); //page
              paragraph.insertBreak('page', 'after'); //page
          } else {
            paragraph.insertParagraph('','after').detachFromList();
            //paragraph.insertBreak('next', 'after'); //page
            paragraph.insertBreak('page', 'after');
          }
          // DEBUG: info print number of paragraphs present to docx
          // docBody.insertText(`${paragraphs.items.length}`,'start');
          // built table
          const num= globalValues.tableHeaderText[globalValues.language].num;
          const sum= globalValues.tableHeaderText[globalValues.language].sum;
          let data = [
            [num, sum]//,
            //[searchResults.items.length+1, txt]
          ];
          // table style and content control
          var table = docBody.insertTable(1, 2, "end", data);
          //table.styleBuiltIn=Word.Style.Normal;
          table.styleBuiltIn = Word.Style.gridTable5Dark_Accent2;
          table.styleFirstColumn = false;
          var tableContentControl= table.insertContentControl();
          tableContentControl.title="first";
          tableContentControl.tag="first";
          await context.sync();
          // set the cell width of the Table.
          var myTables = docBody.tables;
          context.load(myTables);
          await context.sync();
          for (var i=0;i<myTables.items.length;i++){
            var firstCell= myTables.items[i].getCell(0, 0),
                secondCell=myTables.items[i].getCell(0, 1);
            myTables.items[i].load('parentContentControlOrNullObject');
            firstCell.load(["columnWidth"]);
            secondCell.load(["columnWidth"]);
            await context.sync();
            const myCC=myTables.items[i].parentContentControlOrNullObject;
            if (myCC.title==="first"){
              console.log(firstCell.columnWidth);
              firstCell.columnWidth=50;
              secondCell.columnWidth=400;
              let data =[
                [`${searchResults.items.length+1}`, txt]
              ];
              myTables.items[i].addRows('end',1,data);
              await context.sync();
            }
          }
        } else {
          for (var i=0;i<myTables.items.length;i++){
              myTables.items[i].load('parentContentControlOrNullObject');
              // sync
               await context.sync();
               var myCC=myTables.items[i].parentContentControlOrNullObject
               if (myCC.isNullObject){
                 //docBody.insertText(`There are tables in document ${myTables.items[i].parentContentControlOrNullObject}`,'start');
                 console.log(`There are tables in document ${myTables.items[i].parentContentControlOrNullObject}`);
               } else if (myCC.title =="first"){
                  // insert the new reference to docx
                  range.insertText(`${searchValue} ${0}`,'replace');
                  range.font.bold=true;
                  //range.insertText(` ${searchValue} ${searchResultsBold} `,'replace');
                  var searchValueContentControl = range.insertContentControl();
                  searchValueContentControl.title = "ref";
                  searchValueContentControl.tag = "0";
                  searchValueContentControl.cannotEdit=false;//true;
                  searchValueContentControl.appearance = "BoundingBox"; //Hidden";
                  searchValueContentControl.placeholderText=text.AppMsg.PlaceHolderText[globalValues.language];
                  rangeInt.select("End");
                  await context.sync();
                  // append the reference data to table.
                  let data =[
                    //[`${searchResultsBold}`,txt]
                    [`${0}`,txt]
                  ];
                  //docBody.insertText(`Title of table is ${myTables.items[i].parentContentControlOrNullObject.title}`,'start');
                  myTables.items[i].addRows('end',1,data);
              }
            }
        }
        // add single item to form.
        //var crossRefs=document.getElementById('myForm');
        //var opt= document.createElement('option');
        //opt.appendChild(document.createTextNode(`${searchResultsBold} ${txt}`));
        //crossRefs.appendChild(opt);
        // erase text from reference description field
        eraseText();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
  // update document ordered.
  if (globalValues.autoFileAdd){
    asyncUpdateContentControl(false).then(renderPDF.getFile);
  } else {
    asyncUpdateContentControl(false);
  }
  // Get the files and insert in Docx.
//  getFile();
}



//
// Add table input to cross reference form
//
function addOptionToForm (data,imax) {
  var crossRefs=document.getElementById('myForm');
  for (var i=0;i<imax;i++){
    var opt= document.createElement('option');
    opt.appendChild(document.createTextNode(`${i+1} ${data[i].text}`));
    crossRefs.appendChild(opt);
  }
//
// Remove table inputs from the cross reference list
//
}
function removeOptionInForm(){
  var crossRefs=document.getElementById('myForm');
  // remove all values
  while (crossRefs.firstChild){
    crossRefs.removeChild(crossRefs.firstChild);
  }
  var opt= document.createElement('option');
  opt.setAttribute('selected',false,'disabled',true);
  opt.appendChild(document.createTextNode('Choose and insert cross reference'));
  crossRefs.appendChild(opt);
}
//
// Add table input to Dropdown menu.
//
function addOptionToDropdown(data,imax){
  var ref =   document.getElementById("myDropdown");
  for (var i=0;i<imax;i++){
    var button =   document.createElement("button");
    button.setAttribute("id",`${i+1}`);
    button.setAttribute("class","dropbtn");
    button.onclick=testFunction // asign an onclick function.
    button.appendChild(document.createTextNode(`${i+1} ${data[i].text}`));
    ref.appendChild(button);
  }
}
//
// Remove table inputs from the dropdown menu,
//
function removeOptionInDropdown(){
  var crossRefs=document.getElementById('myDropdown');
  // remove all values
  while (crossRefs.firstChild){
    crossRefs.removeChild(crossRefs.firstChild);
  }
}
//
// erase text from description reference field after insert.
//
function eraseText(){
  document.getElementById("description").value="";
}
//
// Set displayed list item based on selection by name.
//
function setSelectedIndex(s,v){
  for (var i=0;i<s.options.length;i++){
    console.log(s.options[i].text);
    if (s.options[i].text==v){
      s.options[i].selected=true;
      return;
    }
  }
}
//
// insert cross-reference with the dropdown menu.
//
function testFunction(){
  var index= this.id;
  Word.run(function (context) {
  //  var searchValue="zie productie ";
    var searchValue="zie "+ searchValueGlobal+" ";
    var doc= context.document;
    var range= doc.getSelection();
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    // If the button in the dropdown list is pressed, close the dropdown.
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        console.log('dos this work');
        openDropdown.classList.remove('show');
      }
    }
    // sync with docx, and insert the cross-reference at cursor location.
    return context.sync().then(function () {
      let firstWord = index;
      range.insertText(` ${searchValue+firstWord} `,'replace');
      var searchCrossValueContentControl = range.insertContentControl();
      searchCrossValueContentControl.title = "crossRef";
      searchCrossValueContentControl.tag = firstWord.toString();
    })
    .then(context.sync);
  })
  .catch(function (error) {
      console.log("Error: " + error);
      if (error instanceof OfficeExtension.Error) {
          console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
  });
}

function newTest(){
  //asyncUpdate();
  //asyncUpdateContentControl(false);
  document.getElementById("myDropdown").classList.toggle("show");
}

//
//
//
/*function openNav() {
  document.getElementById("myNav").style.width = "50%";
}*/
//
//
//
function openNavOffOn() {
  //document.getElementById("myNavOffOn").style.width = "50%";
  const btn = document.getElementById("onoff");
  btn.disabled=true;

  setTimeout(()=>{
    btn.disabled=false;},4000)

  UIkit.notification({
    message: text.HomeHTML.FileAddMsg.nl,
    status: 'warning',
    pos: 'top-center',
    timeout: 4000
  });
}
//
//
//
async function onOffInit(){
  await Word.run(async function (context) {
    var doc= context.document;
    const resultRef = doc.contentControls.getByTitle(globalValues.ccRefTitle);
    const resultPDF = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
    context.load(resultRef,'tag');
    context.load(resultPDF,'tag');
    // sync.
    await context.sync();
    const totRefs=resultRef.items.length;
    const totPDFs=resultPDF.items.length;
    // Get on/off value.
    const currentvalue = document.getElementById('onoff').value;
    // If there are no Refs and Files in the Docx, then it is allowed to switch
    // between the on and off option for automatic adding of files.
    /*if(totRefs===0 && totPDFs===0){
      document.getElementById("onoff").value="On";
      globalValues.autoFileAdd=true;
    }else if(totRefs!=0 && totPDFs===0){
      document.getElementById("onoff").value="Off";
      globalValues.autoFileAdd=false;
    }else if(totRefs!=0 && totPDFs!=0){
      document.getElementById("onoff").value="On";
      globalValues.autoFileAdd=true;
    }*/

    document.getElementById("onoff").value="Off";
    globalValues.autoFileAdd=false;
})
.catch(function (error) {
    console.log("Error: " + error);
    if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
    }
});
}
//
//
//
async function onoff(){
  await Word.run(async function (context) {
    var doc= context.document;
    const resultRef = doc.contentControls.getByTitle(globalValues.ccRefTitle);
    const resultPDF = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
    context.load(resultRef,'tag');
    context.load(resultPDF,'tag');
    // sync.
    await context.sync();
    const totRefs=resultRef.items.length;
    const totPDFs=resultPDF.items.length;
    // Get on/off value.
    const currentvalue = document.getElementById('onoff').value;
    // If there are no Refs and Files in the Docx, then it is allowed to switch
    // between the on and off option for automatic adding of files.
    /*if(currentvalue == "Off" && totRefs===0 && totPDFs===0){
      document.getElementById("onoff").value="On";
      globalValues.autoFileAdd=true;
    }else if(currentvalue == "On" && totRefs===0 && totPDFs===0){
      document.getElementById("onoff").value="Off";
      globalValues.autoFileAdd=false;
    // It there are Refs and Files
    }else if(currentvalue == "On" && totRefs!=0 && totPDFs!=0){
      openNav();
    }else if(currentvalue == "Off" && totRefs!=0 && totPDFs===0){
      openNavOffOn();
    }*/
    if(currentvalue == "Off"){
      openNavOffOn();
    }
})
.catch(function (error) {
    console.log("Error: " + error);
    if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
    }
});
}
//
//
//
async function pdfDelete(){
  await Word.run(async function (context) {
    var doc= context.document;
    const secs = doc.sections;
    var count=0;
    var delNmbr=0;
    const resultPDF = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
    context.load(resultPDF,'tag');
    // sync.
    await context.sync();
    // delete the files.
    for (var i=0;i<resultPDF.items.length;i++){
      resultPDF.items[i].insertText('1', 'Replace');
      resultPDF.items[i].delete(true);
    }
    //The pages are still present in the docx and soley contain the unique id,
    //load all the sections and search for the unique id and remove the sections.
    secs.load("body");
    await context.sync();
    console.log('Number of sections:',secs.items.length);
    // count the total number of sections that need to be deleted.
    for (var i=0;i<secs.items.length;i++){
      if (secs.items[i].body.text==1){
        count++;
      }
    }
    // Loop through the number of sections to delete.
    for (var j=0;j<count/2;j++){
      for (var i=0;i<secs.items.length;i++){
        // when sections contains unique id.
        if (secs.items[i].body.text==1){
          console.log(secs.items[i].body.text,i)
          // select title page
          if (delNmbr===0){
            delNmbr++;
          // select file page
          } else if(delNmbr===1){
            var indx=i
          }
        }
      }
      // Delete the sections.
      const secPrev = secs.items[indx-2];
      const sect = secs.items[indx];
      // select how the range is selected based on is the file is inserted
      // at the end or in between existing files.
      if (indx===secs.items.length-1){
        console.log('delete at end of docx')
        var sectRng = sect.body.getRange("Whole");
      } else {
        var sectRng = sect.body.getRange("Content");
      }
      const secStart = secPrev.body.getRange("End");
      var sectAll = secStart.expandTo(sectRng);
      sectAll.delete ();
      await context.sync();
      // Reset the check variable
      delNmbr=0;
      // Reload the sections from the docx.
      secs.load("body");
      await context.sync();
      console.log('Number of sections:',secs.items.length);
    }
    // set button for automatic file adding off.
    document.getElementById("onoff").value="Off";
    globalValues.autoFileAdd=false;
    // close the overlay.
    document.getElementById("myNav").style.width = "0%";
})
.catch(function (error) {
    console.log("Error: " + error);
    if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
    }
});
}
//
//
//
function pdfReturn(){
  // close the overlay.
  document.getElementById("myNav").style.width = "0%";
}
function pdfReturnOffOn(){
  // close the overlay.

  document.getElementById("myNavOffOn").style.width = "0%";
}
