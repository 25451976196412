//
// Module for setting,checking the used reference style in the docx.
//

var searchValues=require("./searchValues");

const options=require("./options");
const globalValues=require("./global");
const refStyles=globalValues.refStyles;
const refStylesEn=globalValues.refStylesEn;
//
//
//
Office.onReady((info) => {
  if (info.host === Office.HostType.Word) {
//    document.getElementById("refStyle").onchange= updateRefStyle;

  }

});
//
// Change reference style when menu selection is changed, based on contentControls
//
async function updateRefStyle(){
  await Word.run(async function (context) {
    var doc = context.document.body;
    // get text from docx.
    //const cont= docBody.load("text");
    var resultRef = doc.contentControls.getByTitle(globalValues.ccRefTitle);
    var resultCrossRef=doc.contentControls.getByTitle(globalValues.ccCrossRefTitle);
    const resultPDF = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
    context.load(resultPDF,'tag');
    context.load(resultRef,'tag');
    context.load(resultCrossRef,'tag');
    // get the selected refStyle value from menu.
    const changedSearchValue=setDocRefStyle();
    // sync.
    await context.sync();
    //
    globalValues.searchValueGLB=changedSearchValue;
    // check if there are references present in docx.
    if (resultRef.items.length!=0){
      for (var i=0;i<resultRef.items.length;i++){
        const indx=resultRef.items[i].tag;
        resultRef.items[i].cannotEdit=false;
        resultRef.items[i].insertText(`${changedSearchValue} ${indx}`, "Replace");
        await context.sync();
        resultRef.items[i].cannotEdit=true;
      }
    }
    // Check if there are cross-references present in docx.
    if (resultCrossRef.items.length!=0){
      for (var i=0;i<resultCrossRef.items.length;i++){
        const indx=resultCrossRef.items[i].tag;
        resultCrossRef.items[i].cannotEdit=false;
        if (globalValues.prefix && globalValues.bracket){
          resultCrossRef.items[i].insertText(`(${globalValues.refCrossStyle} ${changedSearchValue} ${indx})`, "Replace");
        } else if(!globalValues.prefix && globalValues.bracket) {
          resultCrossRef.items[i].insertText(`(${changedSearchValue} ${indx})`, "Replace");
        } else if(globalValues.prefix && !globalValues.bracket) {
          resultCrossRef.items[i].insertText(`${globalValues.refCrossStyle} ${changedSearchValue} ${indx}`, "Replace");
        } else {
          resultCrossRef.items[i].insertText(`${changedSearchValue} ${indx}`, "Replace");
        }

        await context.sync();
        resultCrossRef.items[i].cannotEdit=true;
      }
    }
    // Check if there are files present in docx.
    if(resultPDF.items.length!=0){
      for (var i=0;i<resultPDF.items.length;i+=2){
        const indx=resultPDF.items[i].tag;
        resultPDF.items[i].insertText(`${changedSearchValue} ${indx}`, "Replace");
        await context.sync();
      }
    }
  })

}
function newcheckRefStyle() {
    Word.run(function (context) {
      var doc = context.document.body;
      // get text from docx.
      var resultRef = doc.contentControls.getByTitle(globalValues.ccRefTitle);
      context.load(resultRef,'text');
      // sync with docx
      return context.sync().then(function () {
        if (resultRef.items.length===0){
          globalValues.searchValueGLB=setDocRefStyle();
        } else{
          for (var i=0;i<refStyles.length;i++){
            const foundRef= resultRef.items[0].text;
            if (foundRef.match(refStyles[i])){
              globalValues.searchValueGLB=refStyles[i];
              setRefStyle(i+1);
            }
          }
        }
      })
      .then(context.sync);
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}
//
// Function to set and check the used reference style.
//
async function checkRefStyle(){
  await Word.run(async function (context) {
    var doc = context.document.body;
    // get text from docx.
    var resultRef = doc.contentControls.getByTitle(globalValues.ccRefTitle);
    context.load(resultRef,'text');
    // sync.
    await context.sync();
    if (resultRef.items.length===0){
      globalValues.searchValueGLB=setDocRefStyle();
    } else{
        const refItems= Object.values(globalValues.refStyleTest[globalValues.language]);
        for (var i=0;i<refItems.length;i++){
          const foundRef= resultRef.items[0].text;
          if (foundRef.match(refItems[i])){
            console.log(refItems[i]);
            globalValues.searchValueGLB=refItems[i];
            setRefStyle(i+1);
          }
        }
    }
    // set button text.
    console.log(globalValues.language);
    switch (globalValues.language) {
        case 'en':
            document.getElementById("insert-reference").innerHTML=`Insert new ${globalValues.searchValueGLB}`;
            break;
        case 'nl':
            document.getElementById("insert-reference").innerHTML=`Nieuwe ${globalValues.searchValueGLB} invoegen`;
            break;
    }
  })
  // set the cross reference language
  setDocCrossRefStyle();
}
/*
*
*/
function setDocCrossRefStyle(){
  switch (globalValues.language) {
    case 'en':
      globalValues.refCrossStyle='see';
      break;
    case 'nl':
      globalValues.refCrossStyle='zie';
      break;
  }
}
//
// Set the reference style based on the html option menu.
//
function setDocRefStyle(){
  var e = document.getElementById("refStyle");
  console.log('Reference style selected in menu: ',e.innerHTML)
  var result, selectRefStyle;
  // select refstyle base on language.
  switch (globalValues.language) {
    case 'en':
      selectRefStyle= Object.values(globalValues.refStyleTest[globalValues.language]);
      break;
    case 'nl':
      selectRefStyle= Object.values(globalValues.refStyleTest[globalValues.language]);//refStyles;
      break;
  }
  // set the language.
  for (i=0;i<selectRefStyle.length;i++){
    if (e.innerHTML === selectRefStyle[i]){
      result=selectRefStyle[i];
    }
  }

 console.log(result);
 return result;
};
//
// Set refstyle in list equal to refstyle in docx.
//
function setRefStyle(val){
  const refItems= Object.values(globalValues.refStyleTest[globalValues.language]);
  document.getElementById("refStyle").innerHTML=refItems[val-1];

}


//
// export
//
module.exports=checkRefStyle
