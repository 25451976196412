const globalValues=require("./global");
const ooxml=require("./ooxml");
//
// Insert the rende file in to Docx as base64 format.
//
async function insertFile(obj) {
    await Word.run(async (context) => {
      var pageNumber=0;
      var imin=1;
      var imax=1;
      var fileIndex=[];
      var firstFile=true;
      const refId= globalValues.refIdDocx,
            fileNumber= obj.fileNum,
            totFileNumber=obj.fileNum.length,
            totPageNumber= obj.totNmbPages,
            wordId=obj.wordId,
            base64File=obj.data,
            pdfName=obj.name;
      // Determine the file order, every new reference gets a title page, at the
      // location of the title page a zero is inserted. e.g. [0 11 12 13 0 21 22 0 31].
      for (let k=0;k<fileNumber.length;k++){
        if (k>0 && wordId[k]!=wordId[k-1]){
          firstFile=true
        }
        if (firstFile){
          fileIndex.push(0);
          firstFile=false;
        }
        fileIndex.push(Number(fileNumber[k]));
      }
      console.log(fileIndex);
      // file loop
      for (let j=0;j<totFileNumber;j++){
        var filePos= [];
        var itemNmbr=[];
        var itemId=[];
        var maxFilePos= 0;
        var addTitlePage= true;
        imax= imax+totPageNumber[j];
        console.log('imin:',imin,'imax:',imax);
        var doc= context.document;
        var nmbr= 1+refId.indexOf(Number(wordId[j])); // Get File position of the refereces
        console.log('Id numbers from text:',refId,'Id numbers from file manager:',Number(wordId[j]));
        var fileNmbr=fileIndex.indexOf(Number(fileNumber[j]));
        console.log('index where  the file must be added:',fileNmbr);
        const refStyle = doc.contentControls.getByTitle(globalValues.ccRefTitle);
        resultFiles = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
        context.load(resultFiles,'tag');
        context.load(refStyle,'text');
        // sync.
        await context.sync();

        const nmbrFiles= resultFiles.items.length;
        console.log('number of files',nmbrFiles);
        console.log('file position',nmbr);
        console.log(refStyle.items[nmbr-1].text);
        // Check if files are already added in the docx, if so set imin to imax to skip this file
        // by exiting the page add loop.
        resultFiles.items.forEach((item, k) => {
            const tag= item.tag
            itemId.push(tag.split("/")[0]);
            itemNmbr.push(Number(tag.split("/")[1]));
            filePos.push(1+refId.indexOf(Number(itemId[k])));
            console.log('position files already attached',filePos[k]);
            console.log('reference id',itemId[k]);
            console.log('file array number:',itemNmbr,j);
            // both the unique file Id  and the array index number of the file must correspond
            // because multiple files can be added for the same unique file id.
            if (itemId[k]== wordId[j] && itemNmbr[k]== fileNumber[j]){
              imin=imax;
              console.log('file already added, set imin to imax',imin);
            }
        })

        if (j>0 && wordId[j]==wordId[j-1]){
          console.log('no need for title page, already added!');
          addTitlePage=false;
        }
        // Check if reference id number is already present in docx, if true the titlePage for
        // that reference does not need to be added.
        if (itemId.includes(wordId[j])){
          addTitlePage=false;
          console.log('the file reference id is already present in the word doc!');
        }

        if (filePos.length != 0){
          maxFilePos= Math.max(...filePos);
          console.log('array index',Number(fileNumber[j]));
          console.log('file position based on array index',itemNmbr.indexOf(Number(fileNumber[j])));
          console.log('Largest position of attached file',maxFilePos);
          console.log('position attached files',filePos)
        }
        // page number loop.
        for (let i=imin;i<imax;i++){
          pageNumber=i;
          console.log('Page number added:',pageNumber);
          // check if file must be added at the end of the document.
          if (nmbr > maxFilePos  ){
            console.log('Append files');
            var range = context.document.getSelection();
              if (pageNumber === imin && addTitlePage){
                // insert a title page when a new file is loaded.
                context.document.body.paragraphs.getLast().insertBreak(Word.BreakType.sectionNext, "After"); //page
                var titlePage=context.document.body.paragraphs.getLast();
                titlePage.insertText(`${refStyle.items[nmbr-1].text.replace(/[0-9]/g, '')}`,'replace');
                var titlePageContentControl= titlePage.insertContentControl();
                titlePageContentControl.title = `${globalValues.ccFileTitle}`;
                titlePageContentControl.tag = wordId[j] + "/0/" + pdfName[j];
                titlePageContentControl.styleBuiltIn="Heading1";
                
                context.document.body.paragraphs.getLast().insertBreak(Word.BreakType.sectionNext, "After"); //page
                console.log('untile here it works! 3');
                //  instert the file
                var blankParagraph = context.document.body.paragraphs.getLast();
                blankParagraph.insertInlinePictureFromBase64(base64File[pageNumber-1],"End");
                // add content control.
                var pictureContentControl = blankParagraph.insertContentControl();
                pictureContentControl.title = `${globalValues.ccFileTitle}`;
                pictureContentControl.tag = wordId[j]+"/"+fileNumber[j] + "/" + pdfName[j];
              
                await context.sync();
              } else {
                // insert the other pages if there are any.
                var blankParagraph = context.document.body.paragraphs.getLast();
                blankParagraph.insertInlinePictureFromBase64(base64File[pageNumber-1],"End");
                await context.sync();
            }
          } else {
            console.log('insert files before');
            if (pageNumber === imin && addTitlePage){
              // Insert a new title page
              var titlePage= resultFiles.items[fileNmbr-1].insertParagraph(`${refStyle.items[nmbr-1].text.replace(/[0-9]/g, '')}`, 'Before');
              var titlePageCC= titlePage.insertContentControl();
              titlePage.insertBreak(Word.BreakType.next, "After"); //page
              titlePageCC.title = `${globalValues.ccFileTitle}`;
              titlePageCC.tag = wordId[j] + "/0/" + pdfName[j];
              titlePageCC.styleBuiltIn="Heading1";
              // insert page of file and overwrite the title page of the selected resultFiles.
              var newPage= resultFiles.items[fileNmbr-1];
              const getTag=newPage.tag;
              newPage.clear();
              newPage.insertInlinePictureFromBase64(base64File[pageNumber-1],"Start");
              newPage.title = `${globalValues.ccFileTitle}`;
              newPage.tag = wordId[j]+"/"+ fileNumber[j] + "/" + pdfName[j];

              // Create new title page for resultFiles, previous one is overwritten.
              var newTitlePage=resultFiles.items[fileNmbr-1].insertParagraph(`${refStyle.items[nmbr-1].text.replace(/[0-9]/g, '')}`, 'After');
              newTitlePage.insertBreak(Word.BreakType.next, "Before");
              var newTitlePageCC=newTitlePage.insertContentControl();
              newTitlePageCC.title = `${globalValues.ccFileTitle}`;
              newTitlePageCC.tag = getTag;
              await context.sync();

            } else if(pageNumber === imin && !addTitlePage){
                var newPage=resultFiles.items[fileNmbr-1].insertParagraph('', 'After');
                newPage.insertBreak(Word.BreakType.next, "Before");
                newPage.insertInlinePictureFromBase64(base64File[pageNumber-1],"End");
                var newPageCC=newPage.insertContentControl();
                newPageCC.title = `${globalValues.ccFileTitle}`;
                newPageCC.tag = wordId[j]+"/"+ fileNumber[j] + "/" + pdfName[j];
                await context.sync();
            } else {
              //resultFiles.items[nmbr*2-2].insertInlinePictureFromBase64(base64File[pageNumber-1],"End");
              newPage.insertInlinePictureFromBase64(base64File[pageNumber-1],"End");
              await context.sync();
            }
          }
        }
        // update the page iteration number
        imin=imax;
      }
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
    return;
}
/*
 * Update the numbering of the titlepage.
*/
async function updateFileNumbers() {
  await Word.run(async (context) => {
      var doc= context.document;
      var tagIndex=1;
      var refStyle = doc.contentControls.getByTitle(globalValues.ccRefTitle);
      resultFiles = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
      context.load(resultFiles,'tag');
      context.load(refStyle,'text');
      // sync.
      await context.sync();
      //
      
      if (refStyle.items.length > 0 ){
        var titlePageText= refStyle.items[0].text.replace(/[0-9]/g, '');
      }
      // loop through all attached pages and update numbering titlepage.
      for (var i=0;i<resultFiles.items.length;i++){
          const item= resultFiles.items[i].tag.split("/")[1];
          if (item === '0'){
            resultFiles.items[i].insertText(`${titlePageText}${tagIndex}`,'replace');   // Title page
            // sync.
            await context.sync();
            tagIndex++;
          }
      }
  })
  .catch(function (error) {
      console.log("Error: " + error);
      if (error instanceof OfficeExtension.Error) {
          console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
  });
  return;
}
//
// Export
//
module.exports={
   insertFile, updateFileNumbers
}
