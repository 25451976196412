//
// Function of searching values in docx.
//
function searchValues(cont,searchValue,searchValueCross){
var indxFlag=[];
var cnt=0;
var cntCross=0;
var result=[];
var resultCross=[];
var resultNumber=[];
var strArr=cont.text.split(/\s|\b/);
// search values and the first next character in docx
// split on punctuation and space
  for (var i=0;i<strArr.length;i++){
    if(strArr[i]==searchValue){
      if(strArr[i+1]!=null){
        result[cnt]=strArr[i] + " " + strArr[i+1].replace(/\D/g, "");;
        resultNumber[cnt]=strArr[i+1].replace(/\D/g, "");;
        if(strArr[i-1]!=searchValueCross){
          indxFlag[cnt]=0;
        } else{
          indxFlag[cnt]=1;
        }
        //console.log(`found result: ${result[cnt]}`)
        //console.log(`indxFlag: ${indxFlag[cnt]}`)
        cnt++;
     }
     if(strArr[i-1]===searchValueCross ){
       resultCross[cntCross]=strArr[i-1] + " " +strArr[i] + " " + strArr[i+1].replace(/\D/g, "");;
       //console.log(`found crossRef: ${resultCross[cntCross]}`)
       cntCross++;
     }
   }
  }
  return result
}
//
//
//
module.exports= searchValues
