const globalValues=require("./global");
const renderPDF=require("./insertBase64");
//
// Read the Base64 string from the Docx.
//
async function readFile() {
  var base64Word=[],
      wordId=[];
    await Word.run(async (context) => {
      const doc= context.document,
            firstPicture = doc.body.inlinePictures;

      firstPicture.load("items,width, height");
      // sync
      await context.sync();
      // loop through all inline inlinePictures
      for (let i=0;i<firstPicture.items.length;i++){
        const cc=firstPicture.items[i].parentContentControlOrNullObject;
        // sync
        await context.sync();
        if (!cc.isNullObject){
          const base64 = firstPicture.items[i].getBase64ImageSrc();
          cc.load('tag');
          //sync
          await context.sync();
          // add data to array.
          base64Word.push(base64.value);
          wordId.push(cc.tag);
        }
      }
      })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
    return [wordId,base64Word];
}
//
// Export
//
module.exports={
   readFile
}
