// import
var checkRefStyle=require("./referenceStyle");
const globalValues=require("./global");
const delPDF=require("./delBase64");
const renderPDF=require("./insertBase64");
const text= require("./textSettings");
//
// Function that reads the docx and orders the searchResults in text and in table
// based on the contentControls.
//
async function asyncUpdateContentControl(del) {
      await Word.run(async function  (context){
      const refCrossStyle=globalValues.refCrossStyle;
      var space='\u00A0';
      globalValues.refNumberDocx=[];
      globalValues.refIdDocx=[];
      var resultNumberBold=[];
      var resultNumberCross=[];
      var doc= context.document;
      var docBodys = context.document.body;
      var myTables= docBodys.tables;
      var resultRef = doc.contentControls.getByTitle(globalValues.ccRefTitle);
      var resultCrossRef=doc.contentControls.getByTitle(globalValues.ccCrossRefTitle);
      var test=doc.contentControls;
      // Load from docx.
      context.load(myTables,'parentContentControlOrNullObject');
      context.load(resultRef,'tag');
      context.load(resultCrossRef,'tag');
      // sync.
      await context.sync();
      // Get the reference style.
      var searchValue= globalValues.searchValueGLB;
      // Determine total number of ref and crossRefs.
      var totNmbBoldValues=resultRef.items.length;
      var totNmbCrossValues=resultCrossRef.items.length;
      // Get all crossRef numbers, from the tag.
      if (totNmbCrossValues!=0){
        for (i=0;i<totNmbCrossValues;i++){
          resultNumberCross[i]=resultCrossRef.items[i].tag;
          console.log('crossnumber tag',resultNumberCross[i])
        }
      }
      // Check if there are Refs present in docx.
      if (totNmbBoldValues===0 && totNmbCrossValues===0){
        console.log('no reference items');
      } else {
        for (i=0;i<totNmbBoldValues;i++){
          // allow to edit the values in docx
          resultRef.items[i].cannotEdit=false;
          resultRef.items[i].appearance = "BoundingBox";
          resultRef.items[i].placeholderText=text.AppMsg.PlaceHolderText[globalValues.language];
          //var index=i+1;
          var index=i+parseInt(globalValues.initRefNmbr);
          console.log('what is the loaded inddex',index)
          resultNumberBold[i]=resultRef.items[i].tag;
          globalValues.refNumberDocx[i]=resultNumberBold[i];
          globalValues.refIdDocx[i]=resultRef.items[i].id;
          //Update Ref tag number and write  to docx.
          resultRef.items[i].tag=index.toString();
          if (!globalValues.spaceBreak){
            space=' ';
          }
          resultRef.items[i].insertText(`${searchValue}${space}${index}`, 'Replace');
          resultRef.items[i].font.bold=true;
          await context.sync();

        //  Check if the crossRef index needs to be updateted.
          if (resultNumberBold[i]!=0){
            if(resultNumberCross.includes(resultNumberBold[i])){
              console.log('keep',resultNumberBold[i]);
              for (j=0;j<totNmbCrossValues;j++){
                if (resultNumberBold[i]===resultNumberCross[j]){
                  resultCrossRef.items[j].cannotEdit=false;
                  resultCrossRef.items[j].appearance = "BoundingBox";
                  resultCrossRef.items[j].placeholderText=text.AppMsg.PlaceHolderText[globalValues.language];
                  //Update crossRef tag number and write to docx.
                  console.log('prefix value',globalValues.prefix)
                  if (globalValues.prefix && globalValues.bracket){
                      resultCrossRef.items[j].insertText(`(${refCrossStyle}${space}${searchValue}${space}${index})`, "Replace");
                  } else if(!globalValues.prefix && globalValues.bracket) {
                    resultCrossRef.items[j].insertText(`(${searchValue}${space}${index})`, "Replace");
                  } else if(globalValues.prefix && !globalValues.bracket) {
                    resultCrossRef.items[j].insertText(`${refCrossStyle}${space}${searchValue}${space}${index}`, "Replace");
                  } else {
                    resultCrossRef.items[j].insertText(`${searchValue}${space}${index}`, "Replace");
                  }

                  resultCrossRef.items[j].tag=index.toString();
                  await context.sync();
                  resultCrossRef.items[j].cannotEdit=false; //true;
                }
              }
            }
          }
        // do not allow edit of the value in docx.
        resultRef.items[i].cannotEdit=false;//true;
        
        }
        // Check if the crossRef needs to be deleted, this is true if there is
        // no corresponding Ref in text (i.e. resultNumberBold).
        for (i=0;i<totNmbCrossValues;i++){
          if(resultNumberBold.includes(resultNumberCross[i])===false){
            // allow to edit the values.
            resultCrossRef.items[i].cannotEdit=false;
            resultCrossRef.items[i].delete(false);
            console.log('then delete the value!',resultNumberCross[i]);
          }
        }
      }


      // DEBUG: show total number of bold values.
      console.log(`nmbr of bold values: ${totNmbBoldValues}`);
      console.log('Unique bold search Numbers');
      for (var i=0;i<totNmbBoldValues;i++){
        console.log(resultNumberBold[i]);
      }


      // Code for updating the table order, read the current table content and store
      // and compare with resultNumber.
      for (var i=0;i<myTables.items.length;i++){
           var myCC=myTables.items[i].parentContentControlOrNullObject
           if (myCC.title =="first"){
              var cellContent=[];
              var cellContentNumber=[];
              var totRowNumber= myTables.items[i];
              context.load(totRowNumber,'rowCount');
              await context.sync();
              if (totNmbBoldValues.length!=0){
                // DEBUG: print number of rows in table
                console.log(`Number of rows in table: ${totRowNumber.rowCount}`);
                // check if number of values in table is equal to number of values in docx
                //lengthAll==totNmbBoldValues
                //if ( totNmbBoldValues!=totRowNumber.rowCount-1 ){
                  // check if one value is del in docx
                  //if (totRowNumber.rowCount-totNmbBoldValues-1 ===1){
                  //console.log('1 value DEL from table');

                  for (var j=0;j<totRowNumber.rowCount-1;j++){
                    // Load Table data.
                    cellContent[j]=myTables.items[i].getCell(j+1,1).body;
                    cellContentNumber[j]= myTables.items[i].getCell(j+1,0).body;
                    cellContent[j].load('text');
                    cellContentNumber[j].load('text');
                    await context.sync();
                    // DEBUG: print the number of the Table cell.
                    console.log(parseInt(cellContentNumber[j].text))
                  }
                  // compare searchValues with Table data and reorder
                  for (j=0;j<totNmbBoldValues;j++) {
                    for (k=0;k<totRowNumber.rowCount-1;k++){
                      //console.log('compare:')
                      //console.log(parseInt(resultNumber[j]),parseInt(cellContentNumber[k].text),j,k);
                      if(parseInt(cellContentNumber[k].text)===parseInt(resultNumberBold[j])){ //prev resultNumber
                        console.log(parseInt(cellContentNumber[k].text),j,k);
                        // save cell content.
                        var dmyNumber=cellContentNumber[j];
                        var dmyContent=cellContent[j];
                        // update cell content.
                        cellContentNumber[j]=cellContentNumber[k];
                        cellContent[j]=cellContent[k];
                        // shift cell contentContent, or overwrite.
                        cellContentNumber[k]=dmyNumber;
                        cellContent[k]=dmyContent;
                        // DEBUG: print new and old table positions.
                        console.log('new Table order:')
                        console.log(`Current cell position: ${parseInt(cellContentNumber[j].text)} Old cell position ${k}`);
                    }
                  }
                }
              }
            }
          }


      // delete current table content and replace with the changed order
      for (var i=0;i<myTables.items.length;i++){
           var myCC=myTables.items[i].parentContentControlOrNullObject
           if (myCC.title =="first"){
             myTables.items[i].deleteRows(1,totRowNumber.rowCount-1);
             // check if number of values is equal to number of values in docx
             if (totNmbBoldValues!=totRowNumber.rowCount-1 ){
               //  more than one value del in docx
               if (totRowNumber.rowCount-totNmbBoldValues-1>1){
                 // # of rows to add equals to # of values found
                 var nmbRows=totNmbBoldValues;
              // one value del in docx
               } else {
                 // # of rows to add equals to # of rows minus 1
                 var nmbRows= totRowNumber.rowCount-2;
               }
               for (var j=0;j<nmbRows;j++){
                 let data =[
                   [`${j+parseInt(globalValues.initRefNmbr)}`,cellContent[j].text]
                 ];
                 myTables.items[i].addRows('end',1,data);
               }
             } else {
               for (var j=0;j<totNmbBoldValues;j++){ // prev resultNumber
                 let data =[
                   [`${j+parseInt(globalValues.initRefNmbr)}`,cellContent[j].text]
                 ];
                 myTables.items[i].addRows('end',1,data);

               }

             }

           }

      }

      // add table items to HTML form
      //removeOptionInForm();
      //addOptionToForm(cellContent,totNmbBoldValues);
      searchValueGlobal=searchValue;
      removeOptionInDropdown();
      addOptionToDropdown(cellContent,totNmbBoldValues);
      await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });

    if (del){
      console.log('delete files after update!');
      await delPDF.delAllRefFiles('',false,false);
      await renderPDF.updateFileNumbers();
    } else{
      console.log('only update');
      renderPDF.updateFileNumbers();
    }

}
//
// Add table input to cross reference form
//
function addOptionToForm (data,imax) {
  var crossRefs=document.getElementById('myForm');
  for (var i=0;i<imax;i++){
    var opt= document.createElement('option');
    opt.appendChild(document.createTextNode(`${i+parseInt(globalValues.initRefNmbr)} ${data[i].text}`));
    crossRefs.appendChild(opt);
  }
//
// Remove table inputs from the cross reference list
//
}
function removeOptionInForm(){
  var crossRefs=document.getElementById('myForm');
  // remove all values
  while (crossRefs.firstChild){
    crossRefs.removeChild(crossRefs.firstChild);
  }
  var opt= document.createElement('option');
  opt.setAttribute('selected',false,'disabled',true);
  opt.appendChild(document.createTextNode('Choose and insert cross reference'));
  crossRefs.appendChild(opt);
}
//
// Add table input to Dropdown menu.
//
function addOptionToDropdown(data,imax){
  var ref =   document.getElementById("myDropdown");
  for (var i=0;i<imax;i++){
    var button =   document.createElement("button");
    button.setAttribute("id",`${i+parseInt(globalValues.initRefNmbr)}`);
    button.setAttribute("class","dropbtn");
    button.onclick=testFunction // asign an onclick function.
    button.appendChild(document.createTextNode(`${i+parseInt(globalValues.initRefNmbr)} ${data[i].text}`));
    ref.appendChild(button);
  }
}
//
// Remove table inputs from the dropdown menu,
//
function removeOptionInDropdown(){
  var crossRefs=document.getElementById('myDropdown');
  // remove all values
  while (crossRefs.firstChild){
    crossRefs.removeChild(crossRefs.firstChild);
  }
}
//
// erase text from description reference field after insert.
//
function eraseText(){
  document.getElementById("description").value="";
}
//
// Set displayed list item based on selection by name.
//
function setSelectedIndex(s,v){
  for (var i=0;i<s.options.length;i++){
    console.log(s.options[i].text);
    if (s.options[i].text==v){
      s.options[i].selected=true;
      return;
    }
  }
}
//
// insert cross-reference with the dropdown menu.
//
async function testFunction(){
  var index= this.id;
  await Word.run(async function (context) {
    var space= '\u00A0';
    if (!globalValues.spaceBreak){
      space=' ';
    }
    if (globalValues.prefix && globalValues.bracket){
      var searchValue=`(${globalValues.refCrossStyle}${space}${searchValueGlobal}${space}${index})`;
    } else if(!globalValues.prefix && globalValues.bracket) {
      var searchValue=`(${searchValueGlobal}${space}${index})`;
    } else if(globalValues.prefix && !globalValues.bracket) {
      var searchValue=`${globalValues.refCrossStyle}${space}${searchValueGlobal}${space}${index}`;
    } else {
      var searchValue=`${searchValueGlobal}${space}${index}`;
    }

    var doc= context.document;
    var rangeInt= doc.getSelection();
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    // If the button in the dropdown list is pressed, close the dropdown.
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        console.log('dos this work');
        openDropdown.classList.remove('show');
      }
    }
    // First at a space to detach reference CC box fromt text
    rangeInt.insertText(` `,'End');
    var range= doc.getSelection();
    // sync
    await context.sync();

    // sync with docx, and insert the cross-reference at cursor location.
    //return context.sync().then(function () {
      let firstWord = index;
      //range.insertText(`${searchValue+firstWord}`,'replace');
      range.insertText(`${searchValue}`,'replace');
      range.font.bold=false;
      var searchCrossValueContentControl = range.insertContentControl();
      searchCrossValueContentControl.title = "crossRef";
      searchCrossValueContentControl.tag = firstWord.toString();
      searchCrossValueContentControl.appearance = "BoundingBox"; //Hidden";
      searchCrossValueContentControl.cannotEdit = false; //true;
      searchCrossValueContentControl.placeholderText=text.AppMsg.PlaceHolderText[globalValues.language];

      rangeInt.select("End");
      // sync
      await context.sync();
    //})
    //.then(context.sync);
  })
  .catch(function (error) {
      console.log("Error: " + error);
      if (error instanceof OfficeExtension.Error) {
          console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
  });
}

function newTest(){
  asyncUpdate();
  document.getElementById("myDropdown").classList.toggle("show");
}

module.exports=asyncUpdateContentControl
