/*
 * global variables used in app.
 */

// reference styles.
var refStyles=['appendix','bijlage','productie'];
var refStylesEn=['addendum','appendix','attachment'];
let refStyleTest={
  en: {
    one: "addendum",
    two: "appendix",
    three: "attachment"
  },
  nl: {
    one: "appendix",
    two: "bijlage",
    three: "productie"
  }
};
let tableHeaderText={
  en:{
    num: "Number",
    sum: "Description"
  },
  nl:{
    num: "Nummer",
    sum: "Omschrijving"
  }
}
var refCrossStyle=''; //zie
// Fixed title for the reference and cross-reference content control.
const ccRefTitle='ref';
const ccCrossRefTitle='crossRef';
//
const appPrefix='SmartNumbering';
//
const ccFileTitle='File';
//
var language='';
//
var searchValueGlobal;
var searchValueGLB;
// Array with reference number in the Docx.
var refNumberDocx=[],
    refIdDocx=[];
// Add reference file automatic to Docx.
var autoFileAdd= false;

var maxNumberOfRefs=0;
//
const initRefNmbr=1;
var prefix=false;
var bracket=false;
var autoUpdate=false;
var spaceBreak=false;
var demoMode=true;
// colors options for myTables
const tableColor=["GridTable5Dark_Accent2","GridTable5Dark","GridTable5Dark_Accent1",
                  "GridTable5Dark_Accent3","GridTable5Dark_Accent4","GridTable5Dark_Accent5",
                  "GridTable5Dark_Accent6","PlainTable1"];
var Setting=
     {
        Username: "Username",
        Demomode: "Demomode"
      }

function setValue(key,value){
    sessionStorage.setItem(key,value);
}

function getValue(key){
  return sessionStorage.getItem(key);
}

function setOption(key,value){
  localStorage.setItem(key,value);
}

function getOption(key){
  return localStorage.getItem(key);
}


var setOOXML=false;
//
// Export.
//
module.exports={
  refStyles, searchValueGlobal, refCrossStyle, ccRefTitle, ccCrossRefTitle,
  appPrefix, refNumberDocx, ccFileTitle, autoFileAdd, demoMode, Setting,setValue,
  getValue, maxNumberOfRefs,initRefNmbr,prefix, setOption, getOption,bracket,
  searchValueGLB,  autoUpdate, setOOXML,tableColor, refStyleTest, tableHeaderText
}
