const globalValues=require("./global");
//const img = require('./base64File');
//const renderPDF=require("./insertBase64FullPage");
const renderPDF=require("./insertBase64");
const delPDF=require("./delBase64");
const readPDF=require("./readBase64");
const checkPDF=require("./checkBase64");
let dialog;
export function fileManager(){
  // PRODUCTION URL SmartNumbering
  const url = "https://app.smartnumbering.nl/pdfBuilder.html";
  // PRODUCTION URL Hammock
  //const url = "https://app.hammock.nl/numbering/pdfBuilder.html";
  // DEVELOPMENT URL.
  //const url = "https://localhost:3000/pdfBuilder.html";
  Office.context.ui.displayDialogAsync( url ,{height:100,width:50},
    function (result){
      if(result.error){
        console.error(result.error);
        return;
      }
      //var dialog= result.value;
      dialog= result.value;
      dialog.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
      dialog.addEventHandler(Office.EventType.DialogMessageReceived,async function(asyncResult){
        //console.log(asyncResult.message);
        const message= JSON.parse(asyncResult.message);
        //console.log(message);
        if (message.status === "success") {
          dialog.close();
        } else if (message.status === "init"){
          messageToDialog(message);
        } else if (message.status === "sync"){
          messageToDialog(message);
        } else if (message.status === "writesync"){
          // disable buttons when files are being updated
          document.getElementById("insert-reference").disabled = true;
          document.getElementById("btnDropdown").disabled = true;
          messageToDialog(message);
        } else if (message.status==="write"){
          console.log('insert file');
          // Insert files within the page margin of the Word Doc.
          // Delete only the files that need to be deleted.
          await delPDF.delFile(message);
          await renderPDF.insertFile(message);
          await renderPDF.updateFileNumbers();
          // Delete all the files that are attached in the Word doc. This is
          // necesarry when ooxml is used to set the page marging such that the
          // attached files are full page.
          /*if (!globalValues.setOOXML){
            checkPDF.insertPageBreak(message);
          } else {
            await delPDF.delAllRefFiles(message,true,true);
          }

          //
          await renderPDF.insertFile(message);
          */
          // get the updated canvas.
          var values=[];
          values= await readPDF.readFile();
          console.log('wait for reading base64 from docx....');
          const messageToDialog = JSON.stringify({status: 'sync',canvas: 'true',data:globalValues.refIdDocx,index:values[0],base64:values[1]});
          dialog.messageChild(messageToDialog);
          console.log(message);
          // Enable buttons when files are updated
          document.getElementById("insert-reference").disabled = false;
          document.getElementById("btnDropdown").disabled = false;
        } else {
          console.log('give an error when this happens!');
        }
      });
    });
}
/*
 * Send data to the pdfbuilder displayDialog box, should  mkae use of conitional logic
 * when different messages are send
 */
export async function messageToDialog(arg) {
  console.log('send to dialog box');
  console.log(arg);
  var values=[];
  if (arg.status==='init'){
    console.log('which search value',globalValues.searchValueGLB);
    console.log('read base64 from word');
    values= await readPDF.readFile();
    console.log('wait for reading base64 from docx....');
    console.log(values[0]);
    console.log(values[1]);
    const messageToDialog = JSON.stringify({status: 'init',data:globalValues.refIdDocx,searchValue:globalValues.searchValueGLB,index:values[0],base64:values[1]});
    dialog.messageChild(messageToDialog);
  } else if (arg.status==='sync'){
    const messageToDialog = JSON.stringify({status: 'sync',data:globalValues.refIdDocx});
    dialog.messageChild(messageToDialog);
  } else if (arg.status==='writesync'){
    const messageToDialog = JSON.stringify({status: 'writesync',data:globalValues.refIdDocx, canvas:'false'});
    dialog.messageChild(messageToDialog);
  }
}

function processDialogEvent(arg) {
  switch (arg.error) {
      case 12002:
          showNotification("The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.");
          break;
      case 12003:
          showNotification("The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.");            break;
      case 12006:
          console.log("Dialog closed.");
          break;
      default:
          showNotification("Unknown error in dialog box.");
          break;
  }
}
