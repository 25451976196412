var asyncUpdateContentControl=require("./updateContentControl");
var checkRefStyle=require("./referenceStyle");
const globalValues=require("./global");
//
// Insert references and add to table
//
async function asyncInsertReference() {
    const updatePromise='done';
    await Word.run(async function (context) {
      var doc= context.document;
      var docBody = context.document.body;
      var myTables = context.document.body.tables;
      var paragraphs = context.document.body.paragraphs;

      //var searchValue= 'productie';
      const cont= docBody.load("text");
      // sync
      await context.sync();

      // Get the reference style
      var searchValue= checkRefStyle(cont);
      console.log('----------------------');
      console.log(searchValue);



      var searchResultsBold=1;
      // get app content
      var txt= document.getElementById("description").value;
      // get docx content
      var range= doc.getSelection();
      var serviceNameContentControl = doc.contentControls.getByTitle("first");
      var test = doc.contentControls.getByTitle("ref");

      var searchResults=docBody.search(searchValue,{ignorePunct: true,matchWholeWord:true});
      // load from docx
      context.load(searchResults,['text','font']);
      context.load(serviceNameContentControl,'text');
      context.load(test,'text');
      context.load(myTables);
      context.load(paragraphs, 'listItemOrNullObject' );
      // sync
      await context.sync();
      if (test.items.length===0){
        console.log('no reference items');
      } else {
        console.log(test);
      }
      // Count number of boldface searchResults
        for (var i=0;i<searchResults.items.length;i++){
        if (searchResults.items[i].font.bold){
            searchResultsBold=searchResultsBold+1;
        }
      }
        // check if there is a table in the document if not create one
        // else append to this table
        if (serviceNameContentControl.items.length === 0) {
          // insert reference docx
        //  range.insertText(`${searchValue} 1`,'replace');
        //  range.font.bold=true;
          range.insertHtml(`<b>${searchValue} 1</b>`, 'Replace');
          //
          // content control is added on 29012022 must be further build
          //
          var searchValueContentControl = range.insertContentControl();
          //searchValueContentControl.insertText(`${searchValue} 1`,'replace');
          searchValueContentControl.title = "ref";
          searchValueContentControl.tag = "1";
          searchValueContentControl.cannotEdit=true;
          searchValueContentControl.appearance = "Hidden";
          //searchValueContentControl.color = "blue";
          await context.sync();
          //notEdit();
          //
          //
          //
          //range.insertParagraph('','after').detachFromList();
          //var pageBreak= docBody.insertBreak('page','end');
          // Check if last paragraph is in list then detach new entry form list
          var paragraph = paragraphs.items[paragraphs.items.length-1];
          if (paragraph.listItemOrNullObject.isNullObject){
              paragraph.insertBreak('next', 'after'); //page
          } else {
            paragraph.insertParagraph('','after').detachFromList();
            paragraph.insertBreak('next', 'after'); //page
          }
          // DEBUG: info print number of paragraphs present to docx
          // docBody.insertText(`${paragraphs.items.length}`,'start');
          // built table
          let data = [
            ["Nummer", "Omschrijving"],
            [searchResults.items.length+1, txt]
          ];
          // table style and content control
          var table = docBody.insertTable(2, 2, "end", data);
          //table.styleBuiltIn=Word.Style.Normal;
          table.styleBuiltIn = Word.Style.gridTable5Dark_Accent2;
          table.styleFirstColumn = false;
          var tableContentControl= table.insertContentControl();
          tableContentControl.title="first";
          }
        else {
          for (var i=0;i<myTables.items.length;i++){
              myTables.items[i].load('parentContentControlOrNullObject');
              // sync
               await context.sync();
               var myCC=myTables.items[i].parentContentControlOrNullObject
               if (myCC.isNullObject){
                 //docBody.insertText(`There are tables in document ${myTables.items[i].parentContentControlOrNullObject}`,'start');
                 console.log(`There are tables in document ${myTables.items[i].parentContentControlOrNullObject}`);
               } else if (myCC.title =="first"){
                  // insert the new reference to docx
                  range.insertText(`${searchValue} ${0}`,'replace');
                  range.font.bold=true;
                  //range.insertText(` ${searchValue} ${searchResultsBold} `,'replace');
                  var searchValueContentControl = range.insertContentControl();
                  searchValueContentControl.title = "ref";
                  searchValueContentControl.tag = "0";
                  searchValueContentControl.cannotEdit=true;
                  searchValueContentControl.appearance = "Hidden";
                  await context.sync();
                  // append the reference data to table.
                  let data =[
                    //[`${searchResultsBold}`,txt]
                    [`${0}`,txt]
                  ];
                  //docBody.insertText(`Title of table is ${myTables.items[i].parentContentControlOrNullObject.title}`,'start');
                  myTables.items[i].addRows('end',1,data);
              }
            }
        }
        // add single item to form.
        //var crossRefs=document.getElementById('myForm');
        //var opt= document.createElement('option');
        //opt.appendChild(document.createTextNode(`${searchResultsBold} ${txt}`));
        //crossRefs.appendChild(opt);
        // erase text from reference description field
        eraseText();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
  // update document ordered.
  asyncUpdateContentControl();

  //return updatePromise;
}
//
// erase text from description reference field after insert.
//
function eraseText(){
  document.getElementById("description").value="";
}
//
//
//
module.exports=asyncInsertReference
