const globalValues=require("./global");
//const renderPDF=require("./insertBase64FullPage");
const renderPDF=require("./insertBase64");
const readPDF=require("./readBase64");
var resultFiles=[];
//
// Delete only the files that are deleted, changed position or changed content from the Docx.
//
async function delFile(obj) {
    await Word.run(async (context) => {
      var doc= context.document;
      const secs = doc.sections;
      var itemNmbr=[], 
          itemNmbrArray=[];
      var count=0;
      var fileIndex=[];
      var firstFile=true;
      const fileNumber=obj.fileNum,
            wordId=obj.wordId,
            base64File=obj.data;
      //
      for (let k=0;k<fileNumber.length;k++){
        if (k>0 && wordId[k]!=wordId[k-1]){
          firstFile=true
        }
        if (firstFile){
          fileIndex.push(0);
          firstFile=false;
        }
        fileIndex.push(Number(fileNumber[k]));
      }
      //
      resultFiles = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
      context.load(resultFiles,'tag');
      // sync.
      await context.sync();
      const sizeResultFiles= resultFiles.items.length;
      // read inlinepictures in doc
      values= await readPDF.readFile();
      for (var k=0;k<values[0].length;k++){
        const itemInfo= values[0];
        itemNmbrArray.push(itemInfo[k].split("/")[1]);
      }
      console.log(values[1]);
      console.log(base64File);
      if ( sizeResultFiles != 0 ) {
        // get the file array index (11 12 21 22 31 etc) from attached files in Docx.
        for (var k=0;k<sizeResultFiles;k++){
          const item= resultFiles.items[k];
          const tag= item.tag
          const itemId= tag.split("/")[0];
          itemNmbr.push(tag.split("/")[1]);
          // exclude the title page, indexed as 0.
          if (itemNmbr[k] !='0'){
            // check if file is deleted.
            var deleteCheck=fileNumber.includes(itemNmbr[k]);
            var getIndex=fileIndex.indexOf(Number(itemNmbr[k]));
            console.log(getIndex);
            // check if file switched position. 
            if (getIndex != k ){
              console.log('files switched postion');
              deleteCheck=false;
            }
            // check if file changed content.
            getIndex=itemNmbrArray.indexOf(itemNmbr[k]);
            console.log(getIndex);
            // Compare base64 from Word and Document builder. If base64 does not match the content is changed.
            // If multiple pages are attached only the first needs to be checked.
            //while (itemNmbrArray[getIndex] != Number(itemNmbr[k])){
              if (values[1][getIndex] !== base64File[getIndex]){
                console.log(values[getIndex]);
                console.log(base64File[getIndex]);
                deleteCheck=false;
                console.log('File content is changed, delete file.');
              }
              //getIndex++;
            //}
            if (!deleteCheck){
              // If the reference is not present, the position has changed or the content has changed
              //  replace file with unique id and delete the content control.
              console.log('reference is not present DEL file!');
              resultFiles.items[k].insertText('1', 'Replace');
              resultFiles.items[k].delete(true);
              await context.sync();
            }
          }
        }
        //The pages are still present in the docx and soley contain the unique id,
        //load all the sections and search for the unique id and remove the sections.
        secs.load("body");
        await context.sync();
        console.log('Number of sections:',secs.items.length);
        // count the total number of sections that need to be deleted.
        for (var i=0;i<secs.items.length;i++){
          if (secs.items[i].body.text==1){
            count++;
          }
        }
        console.log('number of pages to be DEL',count);
        // Loop through the number of sections to delete.
        for (var j=0;j<count;j++){

          for (var i=0;i<secs.items.length;i++){
            // when sections contains unique id.
            if (secs.items[i].body.text==1){
              console.log(secs.items[i].body.text,i)
              var indx=i;
            }
          }

          // Delete the sections.
          const secPrev = secs.items[indx-1];
          const sect = secs.items[indx];
          // select how the range is selected based on is the file is inserted
          // at the end or in between existing files.
          if (indx===secs.items.length-1){
            console.log('delete at end of docx');
            var sectRng = sect.body.getRange("Whole");
          } else {
            var sectRng = sect.body.getRange("Content");
          }
          const secStart = secPrev.body.getRange("End");
          var sectAll = secStart.expandTo(sectRng);
          sectAll.delete ();
          await context.sync();
          // Reset the check variable
          delNmbr=0;
          // Reload the sections from the docx.
          secs.load("body");
          await context.sync();
          console.log('Number of sections:',secs.items.length);
        }
      } else {
        console.log('there are no files');
      }

      })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
    // now the insert file function can be called.
    //renderPDF.insertFile(obj)
    return;
}
/*
 * Delete all files that belong to a specified reference this is done when a
 * reference is deleted in Word or delete all files that are attached in Word by
 * setting obj -->
*/
async function delAllRefFiles(obj,set,insert) {
    await Word.run(async (context) => {
      console.log('new! delete all files that belong to ref')
      var doc= context.document;
      var secs = doc.sections;
      var itemNmbr=[];
      var count=0;
      var resultRef = doc.contentControls.getByTitle(globalValues.ccRefTitle);
      resultFiles = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
      context.load(resultRef,'tag');
      context.load(resultFiles,'tag');
      // sync.
      await context.sync();
      const sizeResultFiles= resultFiles.items.length;
      if ( sizeResultFiles != 0 ) {
        // get the file array index (11 12 21 22 31 etc) from attached files in Docx.
        for (var k=0;k<sizeResultFiles;k++){
          const item= resultFiles.items[k];
          const tag= item.tag
          const itemId= tag.split("/")[0];
          itemNmbr.push(tag.split("/")[1]);
          console.log('refnumberdocx itemid')
          console.log(globalValues.refIdDocx,itemId);
          if (!globalValues.refIdDocx.includes(Number(itemId))){
            console.log('reference is not present DEL file!');
            resultFiles.items[k].insertText('1', 'Replace');
            resultFiles.items[k].delete(true);
            await context.sync();
          }

          if (set){
            console.log('delete all files!');
            resultFiles.items[k].insertText('1', 'Replace');
            resultFiles.items[k].delete(true);
            await context.sync();
          }
        }
        //The pages are still present in the docx and soley contain the unique id,
        //load all the sections and search for the unique id and remove the sections.
        secs.load("body");
        await context.sync();
        console.log('Number of sections:',secs.items.length);
        // count the total number of sections that need to be deleted.
        for (var i=0;i<secs.items.length;i++){
          if (secs.items[i].body.text==1){
            count++;
          }
        }
        console.log('number of pages to be DEL',count);
        // Loop through the number of sections to delete.
        for (var j=0;j<count;j++){

          for (var i=0;i<secs.items.length;i++){
            // when sections contains unique id.
            if (secs.items[i].body.text==1){
              console.log(secs.items[i].body.text,i)
              var indx=i;
            }
          }

          // Delete the sections.
          const secPrev = secs.items[indx-1];
          const sect = secs.items[indx];
          // select how the range is selected based on is the file is inserted
          // at the end or in between existing files.
          if (indx===secs.items.length-1){
            console.log('delete at end of docx');
            var sectRng = sect.body.getRange("Whole"); // Whole --> needed if only the last pdf is deleted, End --> is needed if all pdf are deleted.
            var  emptySect=false;
            if (!set){
              emptySect=false;
            }
          } else {
            var sectRng = sect.body.getRange("Content");
          }

          var secStart = secPrev.body.getRange("End"); // End

          var sectAll = secStart.expandTo(sectRng);
          console.log(secPrev,sect);
          sectAll.delete ();
          await context.sync();
          // Reset the check variable
          delNmbr=0;
          // Reload the sections from the docx.
          secs.load("body");
          await context.sync();

          if (emptySect){
            console.log('check for empty page');
            secs = context.document.sections;
            // Queue a command to load the text property for all of the paragraphs.
            secs.load("body");
            // Synchronize the document state by executing the queued commands,
            // and return a promise to indicate task completion.
            await context.sync();
            console.log('Number of sections:',secs.items.length);
            const secLast = secs.items[secs.items.length-1].body.getRange("Start");
            const secNew = secs.items[secs.items.length-2].body.getRange("End");
            secNew.expandTo(secLast).delete();
            // sync.
            await  context.sync();
            emptySect=false;

          }
          console.log('Number of sections:',secs.items.length);
        }
      } else {
        console.log('there are no files');
      }

      })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
    // now the insert file function can be called.
    if (insert){
      //globalValues.setOOXML=false;
      //renderPDF.insertFile(obj);
      return;
    }
    return;
}
/*
 * Delete all files that belong to a specified reference this is done when a
 * reference is deleted in Word or delete all files that are attached in Word by
 * setting obj -->
*/
async function delAllRefFilesNew() {
    await Word.run(async (context) => {
      console.log('new! delete all files that belong to ref')
      var doc= context.document;
      const secs = doc.sections;
      var itemNmbr=[];
      var count=0;

      resultFiles = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
      context.load(resultFiles,'tag');
      // sync.
      await context.sync();
      const sizeResultFiles= resultFiles.items.length;
      if ( sizeResultFiles != 0 ) {
        // get the file array index (11 12 21 22 31 etc) from attached files in Docx.
        for (var k=0;k<sizeResultFiles;k++){
          const item= resultFiles.items[k];
          const tag= item.tag
          const itemId= tag.split("/")[0];
          itemNmbr.push(tag.split("/")[1]);
          console.log('refnumberdocx itemid')
          console.log(globalValues.refIdDocx,itemId);
          if (!globalValues.refIdDocx.includes(Number(itemId))){
            console.log('reference is not present DEL file!');
            resultFiles.items[k].insertText('1', 'Replace');
            resultFiles.items[k].delete(true);
            await context.sync();
          }
        }
        //The pages are still present in the docx and soley contain the unique id,
        //load all the sections and search for the unique id and remove the sections.
        secs.load("body");
        await context.sync();
        console.log('Number of sections:',secs.items.length);
        // count the total number of sections that need to be deleted.
        for (var i=0;i<secs.items.length;i++){
          if (secs.items[i].body.text==1){
            count++;
          }
        }
        console.log('number of pages to be DEL',count);
        // Loop through the number of sections to delete.
        for (var j=0;j<count;j++){

          for (var i=0;i<secs.items.length;i++){
            // when sections contains unique id.
            if (secs.items[i].body.text==1){
              console.log(secs.items[i].body.text,i)
              var indx=i;
            }
          }

          // Delete the sections.
          const secPrev = secs.items[indx-1];
          const sect = secs.items[indx];
          // select how the range is selected based on is the file is inserted
          // at the end or in between existing files.
          if (indx===secs.items.length-1){
            console.log('delete at end of docx');
            var sectRng = sect.body.getRange("Whole"); // Whole
          } else {
            var sectRng = sect.body.getRange("Content");
          }
          const secStart = secPrev.body.getRange("End"); // End
          var sectAll = secStart.expandTo(sectRng);
          console.log(secPrev,sect);
          sectAll.delete ();
          await context.sync();
          // Reset the check variable
          delNmbr=0;
          // Reload the sections from the docx.
          secs.load("body");
          await context.sync();
          console.log('Number of sections:',secs.items.length);
        }
      } else {
        console.log('there are no files');
      }

      })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}
//
// Export
//
module.exports={
   delFile,delAllRefFiles,delAllRefFilesNew
}
