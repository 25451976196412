exports.FreeUser = {
        nl: "Deze functie is alleen voor premium gebruikers.",
        en: "This service is for premium users only. Please go to smartnumbering.com for subscription options."
    };

exports.Error = {
       nl: "Error",
       en: "Error"
   };

exports.HomeHTML = {
     TrialMode: {
         nl: "Welkom! In de demo modus kunnen beperkt referenties worden ingevoegd en geen kruisverwijzingen."
    },
    TrialModeAlt: {
        nl: "Welkom in de demo modus. Klik op opties om je voorkeuren aan te passen",
        en: "Welcome in the demo mode. Use options to change your preferences."
   },
    FileAddMsg: {
        nl: "Toevoegen van documenten wordt niet ondersteund in deze versie van SmartNumbering.",
        en: "File adding in this version of SmartNumbering is not supported."
   },
   WordApiError: {
       nl: "Sorry, de add-in gebruikt Word.js APIs die niet beschikbaar zijn in uw versie van Office.",
       en: "Sorry. The add-in uses Word.js APIs that are not available in your version of Office."
  }
};

exports.LoginHTML={
    WelcomeMsg:{
      nl: "SmartNumbering is een applicatie voor het invoegen en automatisch bijhouden van verwijzingen.",
      en: "SmartNumbering is an application for inserting and automatic tracking of references."
    },
    LoginBtn:{
      en: "Start demo version",
      nl: "Gebruik demo versie"
    },
    Privacy:{
      en: "Privacy ",
      nl: "Privacy "
    },
    Terms:{
      en: "Terms & Conditions ",
      nl: "Voorwaarden "
    }
};

exports.AppMsg={
  EmptyField:{
    nl: "Verwijzing niet ingevoegd. Vul tekstveld korte omschrijving verwijzing in.",
    en: "Reference not added. Provide short discription in the text field."
  },
  PlaceHolderText:{
    nl: "Verwijder deze tekst om verwijzing volledig te verwijderen.",
    en: "Remove this text to completely delete the reference."
  }
}

exports.AppUiText={
  CrossRef:{
    en: "Insert cross-references",
    nl: "Kruisverwijzingen invoegen"
  },
  Save:{
    en: "Save",
    nl: "Opslaan"
  },
  Cancel: {
    en: "Cancel",
    nl: "Annuleren"
  },
  Pdf:{
    en: "Document builder",
    nl: "Document beheer"
  },
  Options:{
    en: "Options",
    nl: "Opties"
  },
  RefText:{
    en: "Short reference description...",
    nl: "Korte omschrijving verwijzing..."
  },
  MenuItem1:{
    en: "Select reference style",
    nl: "Selecteer verwijzing stijl"
  },
  MenuItem2:{
    en: "Initial number",
    nl: "Start nummer"
  },
  MenuItem3:{
    en: "Cross-references between brackets (...)",
    nl: "Kruisverwijzingen tussen haakjes (...)"
  },
  MenuItem4:{
    en: "Add prefix “see” to cross-references",
    nl: "Kruisverwijzingen prefix “zie” toevoegen"
  },
  MenuItem5:{
    en: "Non-breaking space",
    nl: "Harde spatie"
  },
  MenuItem6:{
    en: "Automatic update",
    nl: "Automatisch updaten"
  },
  MenuItem7:{
    en: "Table color",
    nl: "Tabel kleur"
  },
  Confirm:{
    en: "yes",
    nl: "ja"
  }
}
