var data=[];
var resultFiles=[];
const globalValues=require("./global");
const insertRef=require("./insertReference");
var dummy='';
let timeout = null;
let dialogopen = false;
//
//
//
async function getFile (){
// Loaded via <script> tag, create shortcut to access PDF.js exports.
var pdfjsLib = window['pdfjs-dist/build/pdf'];
// The workerSrc property shall be specified.
pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
// check if files must be deleted.
delFiles();
const fileElem=document.getElementById('file-selector');

fileElem.click();
//fileElem.onclick=charge(fileElem);


/*fileElem.addEventListener('change', (event) => {
  clearTimeout(timeout);
  checkFiles();
});*/


fileElem.onclick= charge(fileElem);
fileElem.focus=focus(fileElem);


fileElem.onchange= async function(event){
  clearTimeout(timeout);
  checkFiles(fileElem);
  const selectedFiles = event.target.files[0];
  console.log(selectedFiles)
  if(file=document.getElementById('file-selector').files[0]){
    dummy=false;
    console.log(dummy);
    fileReader= new FileReader();
    fileReader.onload=async function(ev){
      // Asynchronous download of PDF
      var loadingTask = pdfjsLib.getDocument(fileReader.result);
      loadingTask.promise.then(iterate);
    };
    fileReader.readAsArrayBuffer(file);
    //clear file value.
    //fileElem.value='';
  }
}
  //

  //if (dummy && fileElem.value.length===0) console.log('when does this fire')

};
function focus(fileElem){
  console.log('focus')
  if (dialogopen) {
    clearTimeout(timeout);
    timeout = setTimeout(checkFiles(fileElem), 100);
  }
}
function charge(fileElem){
  console.log('clivk')
  clearTimeout(timeout);
  dialogopen = true;
}
//
//
//
function checkFiles(fileElem) {
  dialogopen = false;
  console.log("File count:", fileElem.files.length);
}
//
//
//
function iterate(pdf){
  data=[];
  var pageNumber = 1;
  // Initialize parsing of the first page.
  if (pageNumber <= pdf.numPages) getPage()

  // function loop for all the pages.
  function getPage(){
    pdf.getPage(pageNumber).then(function(page){
      console.log('Page loaded');
      var scale = 1.5;
      var viewport = page.getViewport({scale: scale});

      // Prepare canvas using PDF page dimensions
      //var canvas = document.getElementById('the-canvas');
      // single off screen canvas to render to.
      var canvas=document.createElement('canvas');
      var context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      var renderContext = {
          canvasContext: context,
          viewport: viewport
      };
      var renderTask = page.render(renderContext);
      renderTask.promise.then(function () {
        var dataURL=canvas.toDataURL('image/png');
        //data.push(canvas.toDataURL('image/png'));
        data.push(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
        //console.log(data[0]);
        console.log('Page rendered');
        insertFile(data,pageNumber,pdf.numPages);

        if (pageNumber < pdf.numPages){
            pageNumber++;
            getPage();
          } else {
            return ;
          }
      });
    });
  }
}
//
//
//
async function updateFileNumbers() {
    await Word.run(async (context) => {
        var doc= context.document;
        var tagIndex=1;
        var refStyle = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
        resultFiles = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
        context.load(resultFiles,'tag');
        context.load(refStyle,'text');
        // sync.
        await context.sync();
        //
        for (var i=0;i<resultFiles.items.length;i+=2){
          resultFiles.items[i].tag = `${tagIndex}`;   // Title page
          resultFiles.items[i].insertText(`${refStyle.items[i].text.replace(/[0-9]/g, '')}${tagIndex}`,'replace');   // Title page
          resultFiles.items[i+1].tag = `${tagIndex}`; // File pages
          tagIndex++;
          // sync.
          await context.sync();
        }
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}
//
//
//
async function backupupdateFileNumbers() {
    await Word.run(async (context) => {
        var doc= context.document;
        //const nmbr= globalValues.refNumberDocx.length
        for (var i=0;i<globalValues.refNumberDocx.length;i++){
          var indx=globalValues.refNumberDocx.length-i-1;
          console.log(indx)
          var nmbr=globalValues.refNumberDocx[indx];
          resultFiles = doc.contentControls.getByTitle(`${globalValues.ccFileTitle} ${nmbr.toString()}`);
          console.log(`${globalValues.ccFileTitle} ${nmbr.toString()}`)
          context.load(resultFiles,'tag');
          // sync.
          await context.sync();
          console.log(resultFiles.items.length);

          for (var j=0;j<resultFiles.items.length;j++){
            resultFiles.items[j].title = `${globalValues.ccFileTitle} ${indx+1}`;
            resultFiles.items[j].tag = `${indx+1}`;
            // sync.
            await context.sync();
          }
        }
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}
//
// Insert the rende file in to Docx as base64 format.
//
//async function insertFile(base64File,pageNumber,maxNumber) {
async function insertFile(base64File,maxNumber) {
    await Word.run(async (context) => {
      var pageNumber=0;
      for (let i=1;i<base64File.length;i++){
        pageNumber=i;
        console.log(base64File[pageNumber-1]);

        var doc= context.document;
        var nmbr=getFilePosition();

        const refStyle = doc.contentControls.getByTitle(globalValues.ccRefTitle);
        resultFiles = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
        context.load(resultFiles,'tag');
        context.load(refStyle,'text');
        // sync.
        await context.sync();
        console.log('number of files',resultFiles.items.length)
        console.log('file position',nmbr);
        console.log(refStyle.items[nmbr-1].text);
        // if the reference is added on the last postition the file can be added
        // at the end of the docx.
        if (nmbr===globalValues.refNumberDocx.length) {
          var range = context.document.getSelection();
          if (pageNumber === 1){
            // insert a title page when a new file is loaded.
            context.document.body.paragraphs.getLast().insertBreak(Word.BreakType.next, "After"); //page
            var titlePage=context.document.body.paragraphs.getLast();
            //titlePage.insertText('Bijlage','replace');
            titlePage.insertText(`${refStyle.items[nmbr-1].text.replace(/[0-9]/g, '')}`,'replace');
            var titlePageContentControl= titlePage.insertContentControl();
            titlePageContentControl.title = `${globalValues.ccFileTitle}`;
            titlePageContentControl.tag = nmbr.toString();
            titlePageContentControl.styleBuiltIn="Heading1";
            context.document.body.paragraphs.getLast().insertBreak(Word.BreakType.next, "After"); //page
            await context.sync();
            //  instert the file
            var blankParagraph = context.document.body.paragraphs.getLast();//.insertParagraph("", "After");
            //context.document.body.insertInlinePictureFromBase64(base64File,"End");
            blankParagraph.insertInlinePictureFromBase64(base64File[pageNumber-1],"End");
            // add content control.
            var pictureContentControl = blankParagraph.insertContentControl();
            pictureContentControl.title = `${globalValues.ccFileTitle}`;
            pictureContentControl.tag = nmbr.toString();//pageNumber.toString();
            await context.sync();
          } else {
            // insert the other pages if there are any.
            var blankParagraph = context.document.body.paragraphs.getLast();//.insertParagraph("", "After");
            //context.document.body.insertInlinePictureFromBase64(base64File,"End");
            blankParagraph.insertInlinePictureFromBase64(base64File[pageNumber-1],"End");
            await context.sync();
          }


          // Read the base64 encoded string of the image in docx.
          //const firstPicture = context.document.body.inlinePictures.getFirst();
          //const base64 = firstPicture.getBase64ImageSrc();
      // if the reference is added anywhere else, find the next value and corresponding
      // file poistions, and append the added files before.
      } else{
        if (pageNumber===1){
          // Insert a title page
          //var titlePage= resultFiles.items[nmbr*2-2].insertParagraph('Bijlage', 'Before');
          var titlePage= resultFiles.items[nmbr*2-2].insertParagraph(`${refStyle.items[nmbr-1].text.replace(/[0-9]/g, '')}`, 'Before');
          var titlePageCC= titlePage.insertContentControl();
          titlePage.insertBreak(Word.BreakType.next, "After"); //page
          titlePageCC.title = `${globalValues.ccFileTitle}`;
          titlePageCC.tag = "0";
          titlePageCC.styleBuiltIn="Heading1";
          // insert new file at the title page of resultFiles.
          resultFiles.items[nmbr*2-2].clear();
          resultFiles.items[nmbr*2-2].insertInlinePictureFromBase64(base64File[pageNumber-1],"Start");
          resultFiles.items[nmbr*2-2].title = `${globalValues.ccFileTitle}`;
          resultFiles.items[nmbr*2-2].tag = "0";
          // Create new title page for resultFiles, previous one is overwritten.
          //var newPage=resultFiles.items[nmbr*2-2].insertParagraph('Bijlage', 'After');
          var newPage=resultFiles.items[nmbr*2-2].insertParagraph(`${refStyle.items[nmbr-1].text.replace(/[0-9]/g, '')}`, 'After');
          newPage.insertBreak(Word.BreakType.next, "Before");
          var newPageCC=newPage.insertContentControl();
          newPageCC.title = `${globalValues.ccFileTitle}`;
          newPageCC.tag = nmbr.toString();
          await context.sync();
        }else {
          resultFiles.items[nmbr*2-1].insertInlinePictureFromBase64(base64File[pageNumber-1],"End");
          await context.sync();
        }
      }
      // Update the numbering of the files.
      if (pageNumber===maxNumber){
        updateFileNumbers();
      }
    }
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });

}
//
// Insert the rende file in to Docx as base64 format.
//
async function backupinsertFile(base64File,pageNumber,maxNumber) {
    await Word.run(async (context) => {
        console.log(base64File[pageNumber-1]);

        var doc= context.document;
        var nmbr=getFilePosition();
        if (pageNumber===1){
          var val=nmbr;
        }else{
          var val=0;
        }
        //const nmbr= globalValues.refNumberDocx.length
        resultFiles = doc.contentControls.getByTitle(`${globalValues.ccFileTitle} ${val.toString()}`);
        console.log(`${globalValues.ccFileTitle} ${val.toString()}`)
        context.load(resultFiles,'tag');
        // sync.
        await context.sync();
        console.log(resultFiles.items.length)
        // if the reference is added on the last postition the file can be added
        // at the end of the docx.
        if (nmbr===globalValues.refNumberDocx.length) {
          var range = context.document.getSelection();
          if (pageNumber === 1){
            // insert a title page when a new file is loaded.
            context.document.body.paragraphs.getLast().insertBreak(Word.BreakType.next, "After"); //page
            var titlePage=context.document.body.paragraphs.getLast();
            titlePage.insertText('Bijlage','replace');
            var titlePageContentControl= titlePage.insertContentControl();
            titlePageContentControl.title = `${globalValues.ccFileTitle} ${nmbr}`;
            titlePageContentControl.tag = nmbr.toString();
            titlePageContentControl.styleBuiltIn="Heading1";
            context.document.body.paragraphs.getLast().insertBreak(Word.BreakType.next, "After"); //page
            await context.sync();
            //  instert the file
            var blankParagraph = context.document.body.paragraphs.getLast();//.insertParagraph("", "After");
            //context.document.body.insertInlinePictureFromBase64(base64File,"End");
            blankParagraph.insertInlinePictureFromBase64(base64File[pageNumber-1],"End");
            // add content control.
            var pictureContentControl = blankParagraph.insertContentControl();
            pictureContentControl.title = `${globalValues.ccFileTitle} ${nmbr}`;
            pictureContentControl.tag = nmbr.toString();//pageNumber.toString();
            await context.sync();
          } else {
            // insert the other pages if there are any.
            var blankParagraph = context.document.body.paragraphs.getLast();//.insertParagraph("", "After");
            //context.document.body.insertInlinePictureFromBase64(base64File,"End");
            blankParagraph.insertInlinePictureFromBase64(base64File[pageNumber-1],"End");
          }


          // Read the base64 encoded string of the image in docx.
          //const firstPicture = context.document.body.inlinePictures.getFirst();
          //const base64 = firstPicture.getBase64ImageSrc();
          await context.sync();
      // if the reference is added anywhere else, find the next value and corresponding
      // file poistions, and append the added files before.
      } else{
        if (pageNumber===1){
          // Insert a title page
          var titlePage= resultFiles.items[0].insertParagraph('Bijlage', 'Before');
          var titlePageCC= titlePage.insertContentControl();
          titlePage.insertBreak(Word.BreakType.next, "After"); //page
          titlePageCC.title = `${globalValues.ccFileTitle} 0`;
          titlePageCC.styleBuiltIn="Heading1";
          // insert new file at the title page of resultFiles.
          resultFiles.items[0].clear();
          resultFiles.items[0].insertInlinePictureFromBase64(base64File[pageNumber-1],"Start");
          resultFiles.items[0].title = `${globalValues.ccFileTitle} 0`;
          // Create new title page for resultFiles, previous one is overwritten.
          var newPage=resultFiles.items[0].insertParagraph('hello', 'After');
          newPage.insertBreak(Word.BreakType.next, "Before");
          var newPageCC=newPage.insertContentControl();
          newPageCC.title = `${globalValues.ccFileTitle} ${nmbr}`;
          await context.sync();
        }else {
          resultFiles.items[1].insertInlinePictureFromBase64(base64File[pageNumber-1],"End");
          await context.sync();
        }
      }
      // Update the numbering of the files.
      if (pageNumber===maxNumber){
        updateFileNumbers();
      }
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });

}
//
// Delete the files from docx when the reference is deleted.
//
async function delFiles() {
    await Word.run(async (context) => {
      console.log('the length of del file',globalValues.refNumberDocx.length)
        var doc= context.document;
        const secs = doc.sections;
        var nmbrFiles=0;
        var delNmbr=0;
        var count=0;
        // Load all the files present in docx.
        resultFiles = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
        context.load(resultFiles,'tag');
        // sync.
        await context.sync();
        // Loop through all files and check if the reference is present in text.
        for (var i=0;i<resultFiles.items.length;i+=2){
          if (resultFiles.items.length !=0){
            console.log('Size of content control of file:',resultFiles.items.length)
            console.log('Tag number',resultFiles.items[i].tag)
            if (globalValues.refNumberDocx.includes(resultFiles.items[i].tag)){
              console.log('reference is present in text!');
            // If the reference is not present replace file with unique id and
            // delete the content control.
            } else {
              console.log('reference is not present DEL file!');
              resultFiles.items[i].insertText('1', 'Replace');
              resultFiles.items[i].delete(true);
              resultFiles.items[i+1].insertText('1', 'Replace');
              resultFiles.items[i+1].delete(true);
              await context.sync();
            }
          }
        }
        //The pages are still present in the docx and soley contain the unique id,
        //load all the sections and search for the unique id and remove the sections.
        secs.load("body");
        await context.sync();
        console.log('Number of sections:',secs.items.length);
        // count the total number of sections that need to be deleted.
        for (var i=0;i<secs.items.length;i++){
          if (secs.items[i].body.text==1){
            count++;
          }
        }
        console.log('number of pages to be DEL',count);
        // Loop through the number of sections to delete.
        for (var j=0;j<count/2;j++){
          for (var i=0;i<secs.items.length;i++){
            // when sections contains unique id.
            if (secs.items[i].body.text==1){
              console.log(secs.items[i].body.text,i)
              // select title page
              if (delNmbr===0){
                delNmbr++;
              // select file page
              } else if(delNmbr===1){
                var indx=i
              }
            }
          }
          // Delete the sections.
          const secPrev = secs.items[indx-2];
          const sect = secs.items[indx];
          // select how the range is selected based on is the file is inserted
          // at the end or in between existing files.
          if (indx===secs.items.length-1){
            console.log('delete at end of docx')
            var sectRng = sect.body.getRange("Whole");
          } else {
            var sectRng = sect.body.getRange("Content");
          }
          const secStart = secPrev.body.getRange("End");
          var sectAll = secStart.expandTo(sectRng);
          sectAll.delete ();
          await context.sync();
          // Reset the check variable
          delNmbr=0;
          // Reload the sections from the docx.
          secs.load("body");
          await context.sync();
          console.log('Number of sections:',secs.items.length);
        }
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}
//
// Read the content control of the files attached to the docx.
//
async function getFiles(nmbr) {
    await Word.run(async (context) => {
        var doc= context.document;
        //const nmbr= globalValues.refNumberDocx.length
        resultFiles = doc.contentControls.getByTitle(`${globalValues.ccFileTitle} ${nmbr.toString()}`);
        console.log(`${globalValues.ccFileTitle} ${nmbr.toString()}`)
        context.load(resultFiles,'tag');
        // sync.
        await context.sync();

    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}
//
// Determine the position where the new file must be placed.
//
function getFilePosition(){
  for (var i=0;i<globalValues.refNumberDocx.length;i++){
    console.log(globalValues.refNumberDocx[i],i,globalValues.refNumberDocx.length);
    if (globalValues.refNumberDocx[i]==='0'){
      return i+1;
    }
  }
  // there are no files, first file needs to be added.
  return 1;
};
//
// Export
//
module.exports={getFile, delFiles,updateFileNumbers, insertFile
}
