
// Variable to hold Office Open XML.
    var currentOOXML = "";

    // Gets the OOXML contents of the Word document body and
    // puts the OOXML into a textarea in the add-in.
    function getOOXML_newAPI() {

        // Run a batch operation against the Word Javascript object model.
        Word.run(function (context) {

            // Create a proxy object for the document body.
            var body = context.document.body;

            // Queue a commmand to get the OOXML contents of the body.
            var bodyOOXML = body.getOoxml();

            // Synchronize the document state by executing the queued commands,
            // and return a promise to indicate task completion.
            return context.sync().then(function () {

                // Stashing the OOXML in case
                currentOOXML = bodyOOXML.value;

                console.log(currentOOXML);

            });
        })
        .catch(function (error) {

            // Clear the OOXML, show the error info
            //currentOOXML = "";
            //report.innerText = error.message;

            console.log("Error: " + JSON.stringify(error));
            if (error instanceof OfficeExtension.Error) {
                console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
        });
    }
/*
 *
 */
 async function getOOXML_paragraph(){
   // Run a batch operation against the Word object model.
   await Word.run(async (context) => {

    // Create a proxy object for the paragraphs collection.
    const body= context.document.body;
    const paragraphs = body.paragraphs;

    // Queue a command to load the style property for the top 2 paragraphs.
    paragraphs.load({select: 'style', top: 2} );

    // Synchronize the document state by executing the queued commands,
    // and return a promise to indicate task completion.
    await context.sync();

    // Queue a set of commands to get the OOXML of the first paragraph.
    currentOOXML = paragraphs.items[0].getOoxml();

    // Synchronize the document state by executing the queued commands,
    // and return a promise to indicate task completion.
    await context.sync();
    var itop=currentOOXML.value.search("w:top=");
    const imax =Array.from({length: 12}, (_, i) => i + itop);
    console.log(paragraphs.items);
    console.log('Paragraph OOXML: ' + currentOOXML.value);

    console.log(imax.map(x=>currentOOXML.value[x]))
    var setOOXML= currentOOXML.value.split("");

    console.log('itop=',itop,'val',setOOXML[itop]);

    itop=itop+7;
    var margin =  currentOOXML.value[itop];
    margin=setOOXML[itop];
    //console.log('itop=',itop,'val',margin,isNaN(setOOXML[itop]));

    while (isNaN(margin) != true){
      console.log('index',itop,'val',margin,isNaN(margin));
      setOOXML[itop]='0';
      //console.log(setOOXML[itop])
      //margin =  currentOOXML.value[itop++];
      itop++;
      margin =  setOOXML[itop];
    }

    console.log(setOOXML.join(""));
    setOOXML=setOOXML.join("");
    //var replaceVal= imax.map(x=>currentOOXML.value[x]);
    //var splitOOXML= currentOOXML.value.split(" ");
    //console.log('value to be replaced:',replaceVal.join(''));
    //console.log(splitOOXML.search(replaceVal));

    //console.log(currentOOXML.value);
    const myOOXMLRequest = new XMLHttpRequest();
    let myXML;
    myOOXMLRequest.open('GET', 'customOOXML.xml', false);
    myOOXMLRequest.send();
    if (myOOXMLRequest.status === 200) {
        myXML = myOOXMLRequest.responseText;
    }
    // Queue a commmand to insert OOXML at the last paragraph.
    paragraphs.getLast().insertOoxml(myXML, Word.InsertLocation.replace,false);
    await context.sync();
});
 }

 function writeContent() {
    const myOOXMLRequest = new XMLHttpRequest();
    let myXML;
    myOOXMLRequest.open('GET', 'customOOXML.xml', true);
    myOOXMLRequest.send();
    if (myOOXMLRequest.status === 200) {
        myXML = myOOXMLRequest.responseText;
    }
    Office.context.document.setSelectedDataAsync(myXML, { coercionType: 'ooxml' });
}
//
// Export
//
module.exports={
  getOOXML_newAPI, getOOXML_paragraph, writeContent
}
