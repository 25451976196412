const globalValues=require("./global");
const text =require('./textSettings');
var asyncUpdateContentControl=require("./updateContentControl");
var setSelect=require("./select");

//
// Function to set and check the used reference style.
//
async function initRefNumber(){
  await Word.run(async function (context) {
    var doc = context.document.body;
    // get text from docx.
    var resultRef = doc.contentControls.getByTitle(globalValues.ccRefTitle);
    context.load(resultRef,'tag');
    // sync.
    await context.sync();
    if(resultRef.items.length!=0){
      const foundRef= resultRef.items[0].tag;
      document.getElementById("quantity").value=foundRef;
      globalValues.initRefNmbr=foundRef;
      console.log('what is the read index',globalValues.initRefNmbr);
    }
  })
}

/*
*
*/
function updateAppLanguage(langSelect){
  document.getElementById("lang-used").innerText=langSelect;
  console.log(langSelect);
  globalValues.language=langSelect.toLowerCase();
  const language=globalValues.language;
  setAppLanguage();

  var refStyleMenu= document.getElementById("refStyle");
  var refMenu= document.getElementById("select");
  var select= document.getElementById("select-options");
  select.remove();
  refStyleMenu.remove();

  document.getElementById("refStyle").remove();

  select= document.createElement('select');
  select.setAttribute('id','select-options');
  refMenu.appendChild(select);

  const imax= Object.keys(globalValues.refStyleTest[language]).length;
  const refItems= Object.values(globalValues.refStyleTest[language]);

  for (let i=0;i<imax;i++){
      var item= document.createElement('option');
      item.value=i+1;
      item.innerHTML=refItems[i];
      select.appendChild(item);
  }

  setSelect();

  globalValues.searchValueGLB= refItems[0];

  switch (globalValues.language) {
      case 'en':
          document.getElementById("insert-reference").innerHTML=`Insert new ${globalValues.searchValueGLB}`;
          globalValues.refCrossStyle='see';
          break;
      case 'nl':
          document.getElementById("insert-reference").innerHTML=`Nieuwe ${globalValues.searchValueGLB} invoegen`;
          globalValues.refCrossStyle='zie';
          break;
  }

  // update language in table.
  langTable();
  // update document.
  asyncUpdateContentControl(false);
}
/*
 * Update the language in the table, if the language is changed in the language
 * menu.
 */
async function langTable(){
  await Word.run(async function (context) {
    var myTables = context.document.body.tables;
    const num= globalValues.tableHeaderText[globalValues.language].num;
    const sum= globalValues.tableHeaderText[globalValues.language].sum;
    context.load(myTables);
    await context.sync();

    for (var i=0;i<myTables.items.length;i++){
      var firstCell= myTables.items[i].getCell(0, 0),
          secondCell=myTables.items[i].getCell(0, 1);
      myTables.items[i].load('parentContentControlOrNullObject');
      firstCell.load(["value"]);
      secondCell.load(["value"]);
      await context.sync();
      const myCC=myTables.items[i].parentContentControlOrNullObject;
      if (myCC.title==="first"){
        console.log(firstCell);
        firstCell.value=num;
        secondCell.value=sum;
        //await context.sync();
      }
    }
  })
  .catch(function (error) {
      console.log("Error: " + error);
      if (error instanceof OfficeExtension.Error) {
          console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
  });
}

function setAppLanguage(){
  const language=globalValues.language;//'';
  const val1= text.AppUiText.CrossRef[language],
        val2= text.AppUiText.Save[language],
        val3= text.AppUiText.Cancel[language],
        val4= text.AppUiText.Pdf[language],
        val5=text.AppUiText.Options[language],
        val6=text.AppUiText.RefText[language],
        val7=text.AppUiText.Confirm[language];

  document.getElementById("lang-used").innerText=language.toUpperCase();
  document.getElementById("btnDropdown").innerHTML=val1;
  document.getElementById("save").innerHTML=val2;
  document.getElementById("cancel").innerHTML=val3;
  document.getElementById("pdfBuilder").innerHTML=val4;
  document.getElementById("options").innerHTML=val5;
  document.getElementById("optionsH1").innerHTML=val5;
  document.getElementById("description").placeholder=val6;

  document.getElementById("MenuItem1").innerText=text.AppUiText.MenuItem1[language];
  document.getElementById("MenuItem2").innerText=text.AppUiText.MenuItem2[language];
  document.getElementById("MenuItem3").innerText=text.AppUiText.MenuItem3[language];
  document.getElementById("MenuItem4").innerText=text.AppUiText.MenuItem4[language];
  document.getElementById("MenuItem5").innerText=text.AppUiText.MenuItem5[language];
  document.getElementById("MenuItem6").innerText=text.AppUiText.MenuItem6[language];
  document.getElementById("MenuItem7").innerText=text.AppUiText.MenuItem7[language];

  const imax= document.getElementsByClassName('container').length;
  var confirm= document.getElementsByClassName('container');

  for (let i=0;i<imax;i++){
    confirm[i].childNodes[0].innerText=val7;
  }

}

module.exports= {initRefNumber, updateAppLanguage, setAppLanguage}
