const globalValues=require("./global");

function load(){

  const myDisplayLanguage = Office.context.displayLanguage;


  console.log("Office language: ", myDisplayLanguage);

  if(globalValues.getOption("lang")!=null){
    language=globalValues.getOption("lang");
  } else {
    switch (myDisplayLanguage) {
        case 'en-US':
            language='en';
            break;
        case 'nl-NL':
            language='nl';
            break;
        default:
            language='nl';
      }
      globalValues.setOption("lang",language);
  }

  globalValues.language=language;

  console.log('load menu items');
  console.log(language);
  //var refMenu= document.getElementsByClassName("custom-select");
  var refMenu= document.getElementById("select");
  //refMenu.removeAttribute('id');
  console.log(refMenu)
  var select= document.createElement('select');
  select.setAttribute('id','select-options');
  refMenu.appendChild(select);

  const imax= Object.keys(globalValues.refStyleTest[language]).length;
  const refItems= Object.values(globalValues.refStyleTest[language]);

  for (let i=0;i<imax;i++){
      var item= document.createElement('option');
      item.value=i+1;
      item.innerHTML=refItems[i];
      select.appendChild(item);
  }


}

module.exports=load;
