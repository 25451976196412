const globalValues=require("./global");
const delPDF=require("./delBase64");
/*
 *  Check if there are files attached in the Word doc. If there are files then then
 *  the OOXML is already set to full page, therefore it is not necesarry to add the
 *  OOXMl again. The global setOOXML var is set to true.
 */
async function checkFile() {
    await Word.run(async (context) => {
      console.log('Check if there are files attached')
      var doc= context.document;
      const secs = doc.sections;
      var itemNmbr=[];
      var count=0;

      resultFiles = doc.contentControls.getByTitle(`${globalValues.ccFileTitle}`);
      context.load(resultFiles,'tag');
      // sync.
      await context.sync();
      const sizeResultFiles= resultFiles.items.length;
      if ( sizeResultFiles != 0 ) {
        globalValues.setOOXML=true;
      }
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}
//
//
//
async function insertPageBreak(obj) {
    await Word.run(async (context) => {
      // Create a proxy object for the paragraphs collection.
      const body= context.document.body;
      const paragraphs = body.paragraphs;

      // Queue a command to load the style property for the top 2 paragraphs.
      paragraphs.load({select: 'style'} );

      // Synchronize the document state by executing the queued commands,
      // and return a promise to indicate task completion.
      await context.sync();
      paragraphs.getLast().insertBreak(Word.BreakType.next, "After"); //page
      await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });

    delPDF.delAllRefFiles(obj,true,true);
}
//
// Export
//
module.exports={
   checkFile, insertPageBreak
}
